//https://www.apifox.cn/apidoc/shared-4b942f37-5234-4d5f-85b6-674b6fb02a63 3dcs-v2

// index
export default {
  index_getMoxing:{url:'/api/index/getMoxing',method:'GET'},//获取模型详情内容
  index_getMoxingShop:{url:'/api/index/getMoxingShop',method:'GET'},//获取模型实物商品
  index_queryOrderByMoxingId:{url:'/api/index/queryOrderByMoxingId',method:'GET'},//获取用户是否购买该模型的订单
  index_createOrder:{url:'/api/index/createOrder',method:'POST'},//创建订单
  index_downloadMoxing:{url:'/api/index/downloadMoxing',method:'GET'},//获取模型实物商品
  index_cancelOrder:{url:'/api/index/cancelOrder',method:'POST'},//取消订单
  index_moxing:{url:'/api/index/moxing2ex',method:'GET'},//获取（搜索过滤）首页模型数据列表
  index_categories:{url:'/api/index/categories',method:'GET'},//查询分类（包含二级分类）接口
  index_searchTags:{url:'/api/index/searchTags',method:'GET'},//查询标签列表
  index_banner:{url:'/api/index/banner2',method:'GET'},//获取首页Banner-V2
  index_index:{url:'/api/index/index',method:'GET'},//获取网站信息
}