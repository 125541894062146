<template>
	<div class="containers-search">
		<div class="search-input">
			<div class="search-icon">
				<img src="@/assets/icon/search-icon.svg">
			</div>
			<input type="text" v-model="key" placeholder="搜索标签" @input="search"
			
			/>
			<div class="clear-icon" v-show="key!=''" @click="key=''">
				<span class="iconfont icon-guanbi"></span>
			</div>
		</div>
		<div class="search-results" v-show="key!=''">
			<div class="search-error" v-show="searchOptions==null || searchOptions.length==0">未搜索到相关标签</div>
			<div class="search-list" v-show="searchOptions!=null && searchOptions.length>0">
				<div class="list-item" v-for="(item,index) in searchOptions" :key="index" @click="pushTap(item)">
					{{item.name}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ty-search'
}
</script>
<script setup>
import { ref , getCurrentInstance} from 'vue';
	const key = ref('');//搜索关键词
	const searchOptions=ref([]);//搜索结果
	const _this = getCurrentInstance().proxy;
	const emit = defineEmits(['push'])
	//搜索
	const search=()=>{
		_this.$request({
			urlName:'index_searchTags',
			data:{
				keyword:key.value
			}
		}).then(res=>{
			searchOptions.value= res.data;
		})
	}
	
	//添加
	const pushTap=(parm)=>{
		key.value='';
		emit('push',parm)
	}
	
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.containers-search{
		position: relative;
		.search-input{
			height: 3.125rem;
			width: 100%;
			background: #F2F2F4;
			border-radius: 0.75rem;
			border: 1px solid #E8E8E8;
			position: relative;
			input{
				width: inherit;
				height: inherit;
				border: none;
				outline: none;
				border-radius: inherit;
				background-color: inherit;
				font-family: @font-family;
				font-size: 1.125rem;
				font-weight: 400;
				color: #333333;
				padding-left: 2.625rem;
				padding-right: 2.75rem;
				box-sizing: border-box;
			}
			.search-icon{
				position: absolute;
				width: 2.625rem;
				height: inherit;
				display: flex;
				justify-content: center;
				align-items: center;
				user-select: none;
				img{
					width: 1rem;
					user-select: none;
				}
			}
			.clear-icon{
				position: absolute;
				width:  2.75rem;
				height: inherit;
				right: 0px;
				top: 0px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				.iconfont{
					font-size:1rem ;
				}
			}
		}
		.search-results{
			width: 100%;
			max-height: 14.3125rem;		
			min-height: 5rem;
			background: #FFFFFF;
			box-shadow: 0px 0px 0.875rem 0px rgba(0,0,0,0.11);
			border-radius: 1.125rem;
			position: absolute;
			margin-top: 0.25rem;
			transition: @animation-time all;
			overflow: auto;
			z-index: 9;
			.search-error{
				width: inherit;
				height: 5rem;
				display: flex;
				justify-content: center;
				align-items: center;
				font-family: @font-family;
				font-weight: 400;
				color: #999999;
				font-size: 1rem;
				transition: @animation-time all;
			}
			.search-list{
				transition: @animation-time all;
				padding: 0.625rem 0.375rem;
				.list-item{
					height: 2.5rem;
					border-radius: 0.875rem;
					padding:0px 0.75rem;
					box-sizing: border-box;
					line-height: 2.5rem;
					font-family: @font-family;
					user-select: none;
					transition: @animation-time all;
					cursor: pointer;
				}
				.list-item:hover{
					background: #EFEFEF;
				}
			}
		}
		
	}
	
	::-webkit-scrollbar {
		width:4px;
		height: 4px;
		background-color: rgba(0, 0, 0, 0);
	}
	
	::-webkit-scrollbar-track {
		border-radius:4px;
		background-color: rgba(0, 0, 0, 0);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: #DBDBDB;
	}
</style>