<template>
	<div class="containers-video" @mouseover="onMouseover"
	    @mouseout="onMouseout">
		<video :src="videoSrc"
		ref="modelVideo"
		muted
		loop
		:poster="poster"
		@play="onPlay"
		@pause="onPause"
		></video>
		<div class="video-static" :class="videoStatic=='play'?'video-stop':'video-play'"></div>
	</div>
</template>
<script>
	export default {
		name: 'ty-video'
	}
</script>
<script setup>
	defineProps({
		//视频url
		videoSrc:{
			type: String,
			default:'',
			required:true
		},
		//视频封面
		poster:{
			type: String,
			default:null,
			required:false
		}
	});
	//鼠标移入
	const videoStatic=ref('stop'); //视频状态
	const onPlay=()=>{
		videoStatic.value='play';
	}
	const onPause=()=>{
		videoStatic.value='stop';
	}
	
	import { ref } from 'vue';
	const modelVideo= ref();
	const onMouseover=()=>{
		modelVideo.value.play();
	}
	//鼠标移出
	const onMouseout=()=>{
		modelVideo.value.pause();
	}
	
	
</script>

<style lang="less" scoped>
	.containers-video{
		position: relative;
		.video-static{
			position: absolute;
			width: 33px;
			height: 33px;
			bottom: 11px;
			left: 11px;
			z-index: 9;
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}
		.video-stop{
			background-image:url(@/assets/icon/stop-icon.svg) ;
		}
		.video-play{
			background-image:url(@/assets/icon/play-icon.svg) ;
		}
		video{
			max-width: 100%;
			max-height: 100%;
		}
	}
</style>