<template>
	<div v-show="modelValue" class='md-root'>
		<div class="protocol">
			<div class="top">
				<div class="title">{{title}}</div>
				<span class="iconfont icon-guanbi" @click="close"></span>
			</div>
			<v-md-preview :text="text"></v-md-preview>
			<ty-button :plain="true" class="ty-button" @click="close">关闭</ty-button>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['text', 'modelValue', 'title'],
		emits: ['cancel', 'confirm', 'update:modelValue'],
		name: "markdown",
		setup(props, {
			emit
		}) {
			const close = () => {
				emit('update:modelValue', false)
			}
			return {
				close
			};
		}
	}
</script>

<style lang='less' scoped>
	@import url("../../markdown.less");

	.md-root {
		position: fixed;
		left: 0;
		top: 0;
		height: 100vh;
		width: 100vw;
		background: rgba(0, 0, 0, .5);
		overflow-y: auto;
		z-index: 1000;
		.protocol {
			width: 59.375rem;
			margin: 10rem auto 6.25rem;
			padding: 0 2.375rem;
			background: #fff;
			border-radius: 0.625rem;
			overflow: hidden;
			
			.top{
				display: flex;
				justify-content: space-between;
				align-items: center;
				.icon-guanbi{
					font-size: 1.125rem;
					cursor: pointer;
				}
			}
			.title {
				margin-top: 1.75rem;
				margin-bottom: 1.1875rem;
				color: #333;
				font-size: 1.5rem;
			}

			.ty-button {
				padding-top: 2.125rem;
				padding-bottom: 2.875rem;
				text-align: center;
			}
		}
	}
</style>
