<template>
	<div class="model-array" >
		<div class="model-item" v-for="(item,index) in array" :key="index"
		v-show="item.pid!=null?item.pid==pid:true"
		@click="toggleModel(item.name,index)">
			{{item.name}}
		</div>
	</div>
</template>

<script>
	export default {
		name: 'model-array',
	}
</script>
<script setup>
	defineProps(['array','pid']);
	const emit = defineEmits(['change']);
	//切换模型function
	const toggleModel=(lable,index)=>{
		emit('change',{lable,index});
	}
</script>

<style lang="less" scoped>
	@import url("../../index.less");
	.model-array {
		width: 9.375rem;
		padding-top: 0.25rem;
		padding-bottom: 0.875rem;
		box-sizing: border-box;
		user-select: none;
		.model-item {
			margin: 0.125rem 0.375rem;
			height: 2.5rem;
			cursor: pointer;
			line-height: 2.5rem;
			padding-left: 0.75rem;
			box-sizing: border-box;
			font-weight: 400;
			transition: @animation-time all;
			color: #666666;
			border-radius: 0.875rem;
		}
	
		.model-item:hover {
			color: #333333;
			background: #EFEFEF;
		}
	}
</style>