<template>
	<div class="containers-tool-tip">
		<div class="tip" @click="resetModel">
			<img src="@/assets/icon/reset-icon.svg"/>
			<span>重置</span>
		</div>
		<div class="tip" v-show="modelValue=='non-full'" @click="toggleFull('full')">
			<img src="@/assets/icon/full-icon.svg"/>
			<span>全屏</span>
		</div>
		<div class="tip" v-show="modelValue=='full'" @click="toggleFull('non-full')">
			<img src="@/assets/icon/esc-icon.svg"/>
			<span>退出全屏</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'tool-tip'
	}
</script>
<script setup>
	import { ref } from 'vue';
	defineProps(['modelValue']);//当前状态 non-full 非全屏 full 全屏
	const emit = defineEmits(['update:modelValue','full','reset'])
	const action = ref(0);
	const toggleFull=(type)=>{
		action.value=type;
		emit('update:modelValue', type)
		emit('full',type);
	}
	const resetModel=(e)=>{
		emit('reset',e);
	}
	
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.containers-tool-tip{
		padding: 0.9375rem 1.875rem;
		box-sizing: border-box;
		position: absolute;
		background-color: rgba(0 , 0, 0, 0.14);
		left: calc(50% -  6.25rem);
		border-radius: 1.8125rem;
		bottom: 6.67%;
		display: flex;
		justify-content: space-between;
		user-select: none;
		.tip{
			cursor: pointer;
			width: 5rem;
			flex-direction: column;
			align-items: center;
			display: flex;
			img{
				width: 2.375rem;
				height: 2.375rem;
			}
			span{

				font-size: 1.25rem;
				font-family: @font-family;
				font-weight: 400;
				color: #FFFFFF;
				margin-top: 0.25rem;
			}
		}
		.tip:nth-child(1){
			
			margin-right:1.25rem ;
		}
	}
</style>