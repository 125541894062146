<template>
		<div class="containers-button">
			<button class="primary" :class="plain?'plain':''" :disabled="disabled"><slot></slot></button>
		</div>
</template>

<script>
	export default {
		name: 'ty-button'
	}
</script>
<script setup>
	
	defineProps({
		disabled:{
			type: Boolean,
			default:false,
			required:false
		},
		plain:{
			type: Boolean,
			default:false,
			required:false
		}
	});
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.containers-button{
		
		.primary{
			cursor: pointer;
			padding: 0.625rem 2.1875rem;
			box-sizing: border-box;
			background: @primary-color;
			border-radius: 0.5rem;
			border: 1px solid @primary-color;
			font-size: 1.125rem;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
		}
		.plain{
			background-color: rgba(0, 0, 0, 0);
			color:@primary-color;
		}
		
		.primary:disabled{
			background: #DADADA;
			font-weight: 500;
			color: #FFFFFF;
			cursor: no-drop;
			border-color: #DADADA;
		}
	}
</style>