<template>
<div class="root-dropdown">
	<div class="containers-dropdown" @click="toggleSwitch(!dswitch)"  :class="dswitch?'dropdown-open':''" >
		<div class="dropdown-info">
			<img :src="require('@/assets/icon/'+icon)" class="dropdown-icon">
			<span class="dropdown-label">{{lable}}</span>
		</div>
		<img src="@/assets/icon/down-icon.svg" class="dropdown-switch" >
	</div>
	<div class="dropdown-panel" v-show="dswitch">
		<slot></slot>
	</div>
</div>
</template>
<script>
	export default {
		name: 'dropdown'
	}
</script>
<script setup>
	import { ref } from 'vue';
	defineProps(['lable','modelValue','icon']);
	const emit = defineEmits(['update:modelValue','change']);
	const dswitch = ref(false);
	const toggleSwitch=(Param)=>{
		dswitch.value=Param;
		emit('update:modelValue', Param);
		if(Param) emit('change',Param);
	}
	document.addEventListener('click',function(e){
		let index = e.composedPath().findIndex((res) => {
			if(res.className){
				return res.className.indexOf('root-dropdown')>-1;
			}else{
				return false;
			}
		} )
		if(index<0){
			if(dswitch.value) toggleSwitch(false);
		}
	})
	defineExpose({toggleSwitch})
	
</script>
<style lang="less" scoped>
@import url("../../index.less");
.root-dropdown{
	position: relative;
	display: flex;
	justify-content: end;
	height: auto;
}
.containers-dropdown{
	user-select: none;
	cursor: pointer;
	min-width: 9.375rem;
	border-radius: 0.875rem;
	border: 1px solid #E8E8E8;
	padding:0.8125rem 1.125rem 0.8125rem 0.875rem;
	box-sizing:border-box;
	margin: 0.375rem 0px;
	display: flex;
	transition: @animation-time all;
	align-items: center;
	justify-content: space-between;
	.dropdown-info{
		display: flex;
		align-items: center;
		.dropdown-icon{
			width: 1.5rem;
			height: 1.5rem;
		}
		.dropdown-label{
			font-size: 1rem;
			font-family: @font-family;
			font-weight: 500;
			height: 1.5rem;
			line-height: 1.5rem;
			color: #727782;
			padding-left: 0.25rem;
			box-sizing: border-box;
		}
	}
	.dropdown-switch{
		width: 1.125rem;
		height: 1.125rem;
	}
	
}
.containers-dropdown:hover{
	background: #F2F2F4;
}
.dropdown-open{
	background: #F2F2F4;
}
.dropdown-panel{
	position: absolute;
	background: #FFFFFF;
	box-shadow: 0px 0px 0.875rem 0px rgba(0,0,0,0.11);
	border-radius: 1.125rem;
	top: 4rem;
	z-index: 99;
	
}
</style>