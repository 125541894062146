<template>
	<div class="containers-detail-tabs">
		<img src="@/assets/icon/up.svg" id="up" draggable="false" />
		<swiper class="swiper"
			direction="vertical" 
			:modules="modules"
			:navigation="{prevEl:'#up', nextEl:'#next' }"
			:slidesPerView="3"
			:spaceBetween="0"
			:slidesPerGroup="3"
			>
			<swiper-slide class="slide" v-for="(item,index) in node" :key="index">
				<div  @click="toggleAction(index)">
					<div class="d-type" v-if="item.type=='fbx'" :class="index==action?'action':''"> <img
							src="@/assets/icon/ic_3d_rotation-icon.svg" draggable="false"> </div>
							
					<div class="d-type" v-if="item.type=='sequence'" :class="index==action?'action':''"> <img
							src="@/assets/icon/Sequenceframes.svg" draggable="false"> </div>
							
					<div class="video-type" v-if="item.type=='video'" :class="index==action?'action':''"> <img
							src="@/assets/icon/play-icon.svg" class="v-ico" draggable="false"> </div>
							
					<div class="img-type" v-if="item.type=='img'" :class="index==action?'action':''"> <img :src="item.data" draggable="false">
					</div>
				</div>
				
			</swiper-slide>
	
		</swiper> 
		<img src="@/assets/icon/next.svg" id="next" draggable="false" />
	</div>
</template>
<script>
	export default {
		name: 'detail-tabs'
	}
</script>
<script setup>
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';
	import 'swiper/css';
	import "swiper/css/navigation";
	import "swiper/css/pagination";
	import {
		Pagination,
		Navigation,
		Autoplay
	} from "swiper";
	import {
		ref,
		getCurrentInstance,
		watch
	} from 'vue';
	let props = defineProps(['modelValue', 'node']);
	const emit = defineEmits(['update:modelValue']);
	const _this = getCurrentInstance().proxy;
	const cdn = ref(_this.$site.cdn_url); //cdn
	const action = ref(0);
	// const mediaList = ref([]);
	//媒体数组 
	const modules = [Navigation, Pagination];
	// watch(() => props.node, (newVal, oldVal) => {
	// 	mediaList.value = newVal;
	// 	emit('update:modelValue', mediaList.value[0]);
	// }, {
	// 	immediate: true
	// });
	const toggleAction = (index) => {
		action.value = index;
		emit('update:modelValue', index);
	}
</script>
<style lang="less" scoped>
	.swiper {
		height: 26.2rem;
		
	}
	.slide {
		height: 6.25rem;
	}
	#up{
		margin-bottom: 4rem;
	}
	#next{
		margin-top: 4rem;
	}
	#up ,#next{
		width: 1.75rem;
		height: 1.75rem;
		padding:0.3125rem 0.125rem;
		cursor: pointer;
		user-select: none;
	}
	@import url("../../index.less");
	.containers-detail-tabs {
		text-align: center;
		.d-type {
			width: 6.25rem;
			height: 6.25rem;
			background: rgba(0, 0, 0, 0.14);
			border-radius: 70px;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all @animation-time;
			user-select: none;
			cursor: pointer;

			img {
				width: 4rem;
				height: 4rem;
			}

			border-radius: 50%;
			border:1px solid rgba(0, 0, 0, 0);
		}

		.video-type {
			width: 6.25rem;
			height: 6.25rem;
			background: rgba(0, 0, 0, 0.14);
			border-radius: 1.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			// margin-top: 3.625rem;
			user-select: none;
			cursor: pointer;
			border: 1px solid rgba(0, 0, 0, 0);
			transition: all @animation-time;

			.v-img {
				max-width: 100%;
				max-height: 100%;
			}

			.v-ico {
				position: absolute;
				width: 3.125rem;
				height: 3.125rem;
			}
		}

		.img-type {
			width: 6.25rem;
			height: 6.25rem;
			background: rgba(0, 0, 0, 0.14);
			border-radius: 1.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			user-select: none;
			cursor: pointer;
			border: 1px solid rgba(0, 0, 0, 0);
			// margin-top: 3.625rem;
			transition: all @animation-time;
			overflow: hidden;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.action {
			transition: all @animation-time;
			border-color: @primary-color;
		}
	}
</style>
