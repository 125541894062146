<template>
	<div class="containers-introduce">
		<div class="info">
			<div class="title">
				{{node.name}}
			</div>
			<div class="money">
				<span v-if="node.free_switch" class="free">免费计划<span class="underline">¥{{node.price}}</span></span>
				<span class="default" v-else>¥{{node.price}}</span>
			</div>
			<div class="number">
				<div class="download-number">
					<img src="@/assets/icon/download-icon.svg" />
					{{node.down_count}}
				</div>
				<div class="collect-number">
					<span class="iconfont icon-shoucang"></span>
					{{node.like_count}}
				</div>
			</div>
			<div class="button">
				<detail-button 
				    :is-login="isLogin" :is-collect="collect" :is-flie="node.down_switch === 1"
					:is-free="node.free_switch==1" :is-pay="order?order.status==1:false" @changeCollect="onCollect"
					@tapDownload="download(node.id)" @tapPayment="tapPay()" @tapLogin="userLogin()"></detail-button>
			</div>
		</div>
		<introduce-ad class="ad" v-if="product!=null" :node="product"></introduce-ad>
		<div class="detail-body">
			<div class="tag-list">
				<detail-tag class="detail-tag" v-for="item in node.tags" :key="item.id">{{item.name}}</detail-tag>
			</div>
			<div class="info-list">
				<div class="info-item">
					<div class="item-lable">免费计划</div>
					<div class="item-value" v-if="node!=null">
						<template v-if="node.free_switch==1">
							是
						</template>
						<template v-else>
							否
						</template>
					</div>
				</div>
				<div class="info-item">
					<div class="item-lable">文件类型</div>
					<div class="item-value">{{node.downtype.name}}</div>
				</div>
				<div class="info-item">
					<div class="item-lable">文件大小</div>
					<div class="item-value">{{node.fbx_size}}</div>
				</div>
				<div class="info-item">
					<div class="item-lable">发布时间</div>
					<div class="item-value">{{formatTime(node.createtime)}}</div>
				</div>
				<div class="info-item">
					<div class="item-lable">许可协议</div>
					<div class="item-value link" @click="showProtocol">{{node.license.name}}</div>
				</div>
			</div>
			<div class="all-text">
				{{node.desc_content}}
			</div>
		</div>
		<confirm ref="waitPay">您已下单购买此模型，存在待支付订单</confirm>
		<confirm ref="confirmOrder" :disabled="!serve">
			<div class="order-info-item">
				<div class="key">模型名称：</div>
				<div class="value">{{node.name}}</div>
			</div>
			<div class="order-info-item">
				<div class="key">模型价格：</div>
				<div class="value">
					<template v-if="node.free_switch==1">
						<span class="und-price">{{node.price}}</span><span class="no-price">免费计划</span>
					</template>
					<template v-else>
						¥{{node.price}}
					</template>
				</div>
			</div>
			<div class="order-info-item">
				<div class="key">文件大小：</div>
				<div class="value">{{node.fbx_size}}</div>
			</div>
			<label for="radio" class="radio">
				<span class="radio-bg"></span>
				<input id="radio" type="radio" name="serve" :checked="serve" value="1" @click="onServe" />
				<span class="radio-on"></span>
				<div class="protocol" v-if="node.free_switch!=1">
					<span>我已阅读并同意：此3D模型为虚拟商品，</span>
					<span class="strong">购买成功后无法退货退款</span>
					<span>，您可在此页进行下载，后续模型的使用需遵循</span>
					<span class="link" @click.self="showProtocol">{{node.license.name}}</span>
				</div>
				<div class="protocol" v-else>
					<span>我已阅读并同意：此3D模型在免费计划中，您可免费下载模型文件，后续模型的使用需遵循</span>
					<span class="link" @click.self="showProtocol">{{node.license.name}}</span>
				</div>
			</label>
		</confirm>

		<markdown v-model="visible" :text="node.license.content" :title="node.license.name" />
	</div>
</template>

<script>
	export default {
		name: 'introduce'
	}
</script>
<script setup>
	import {
		nextTick,
		onMounted,
		ref,
		watch,
		getCurrentInstance,
		toRefs,
		computed
	} from 'vue';
	import {
		useRouter
	} from "vue-router";
	import {
		useStore
	} from 'vuex';
	const router = useRouter();
	const store = useStore();
	const waitPay = ref(); //等待支付
	const confirmOrder = ref(); // 确认订单
	const free = ref(true); //是否免费
	const collect = ref(false); //是否已收藏
	const isLogin = computed(() => store.state.isLogin); //是否已登录
	const pay = ref(false); //是否已支付
	const product = ref(null); // 商品
	const serve = ref(false); //服务条款
	const visible = ref(false); //服务条款
	const _this = getCurrentInstance().proxy;
	const order = ref(null); //订单信息
	//模型数据
	let props = defineProps({
		node: {
			type: Object,
			default: null,
			required: true
		}
	});
	const {
		node
	} = toRefs(props);

	/**
	 * 格式时间
	 */
	const formatTime = (data) => {
		return _this.$getTime(data * 1000, 'yyyy/MM/dd');
	}
	const userLogin = () => {
		if (process.env.NODE_ENV === 'development') {
			window.location.replace(
				`https://3dcs.taiyi.cn/launcher-web/#/?redirect_uri=${encodeURIComponent(window.location.href)}`)

		} else {
			window.location.replace(
				`https://3d.taiyi.cn/prod/launcher-web/#/?redirect_uri=${encodeURIComponent(window.location.href)}`
				);
		}
	}

	const showProtocol = () => {
		visible.value = true;
	}


	const onServe = () => {
		serve.value = true;
	}

	/**
	 * 查询是否已收藏
	 */
	const find_IsCollect = () => {
		_this.$request({
			urlName: 'account_isFavorite',
			data: {
				id: node.value.id
			}
		}).then(res => {
			collect.value = res.data;
		})
	}

	/**
	 * 切换收藏
	 */
	const onCollect = (col) => {
		_this.$request({
			urlName: 'account_toggleFavorite',
			data: {
				id: node.value.id
			}
		}).then(res => {
			collect.value = col.value;
		})
	}

	/**
	 * 查询实物商品
	 */
	const find_Shop = () => {
		_this.$request({
			urlName: 'index_getMoxingShop',
			data: {
				id: node.value.id
			}
		}).then(res => {
			product.value = res.data;
		})
	};

	/**
	 * 查询支付状态
	 */
	const find_PayStatic = () => {
		_this.$request({
			urlName: 'index_queryOrderByMoxingId',
			data: {
				id: node.value.id
			}
		}).then(res => {
			order.value = res.data; //存入订单信息
		});
	}

	/**
	 * 点击购买
	 */
	const tapPay = () => {
		//等待支付
		if (order.value && order.value.status == 0) {
			waitPay.value.inquiry({
				title: '提示',
				cancelText: '取消',
				identifyText: '去支付',
				cancelEvent: () => {
					console.log("取消")
				},
				identifyEvent: () => {
					window.location.href = order.value.pay_link;
				}
			});
		} else {
			confirmOrder.value.inquiry({
				title: '确认订单',
				cancelText: '取消',
				identifyText: '确认',
				cancelEvent: () => {
					serve.value = false;
				},
				identifyEvent: () => {
					createOrder();
				}
			});
		}
	};

	/**
	 * 创建订单
	 */
	const createOrder = () => {
		_this.$request({
			urlName: 'index_createOrder',
			data: {
				id: node.value.id
			}
		}).then(res => {
			serve.value = false;
			window.location.href = res.data;
		})
	}

	/**
	 * 下载
	 */
	const download = (id) => {
		_this.$request({
			urlName: 'index_downloadMoxing',
			data: {
				id: id
			}
		}).then(res => {
			window.location.href = res.data;
		})
	}

	onMounted(async () => {
		await store.dispatch('checkUserToken');
		if (!isLogin.value) await store.dispatch('login');
		find_Shop();
		find_PayStatic();
		find_IsCollect();
	});
	watch(
		() => node.value,
		(newvalue, oldvalue) => {
			find_Shop();
			find_PayStatic();
			find_IsCollect();
		}, {
			deep: true
		}
	);
</script>

<style lang="less" scoped>
	@import url("../../index.less");
	.containers-introduce {
		padding: 4.8125rem 1.125rem 1.8125rem 1.125rem;
		box-sizing: border-box;
		user-select: none;
		display: flex;
		flex-direction: column;
		height: 100vh;
		.info {
			padding: 0px 0.8125rem;
			box-sizing: border-box;

			.title {
				font-size: 1.5rem;
				font-family: @font-family;
				font-weight: 400;
				color: #666666;
			}

			.money {
				margin-top: 0.875rem;
				.default {
					font-size: 2.125rem;
					font-family: @font-family;
					font-weight: 600;
					color: #333333;
					height: 3rem;
					line-height: 3rem;
				}

				.free {
					height: 3rem;
					font-size: 2.125rem;
					font-family: @font-family;
					font-weight: 600;
					color: @primary-color;
					line-height: 3rem;

					.underline {
						font-size: 1.5rem;
						font-family: @font-family;
						font-weight: 400;
						color: #999999;
						text-decoration: line-through;
						margin-left: 0.8125rem;
					}
				}
			}

			.number {
				display: flex;
				margin-top: 0.8125rem;

				.download-number {
					display: flex;
					align-items: center;
					font-size: 1.125rem;
					font-family: @font-family;
					font-weight: 400;
					color: #999999;

					img {
						width: 1.5rem;
						height: 1.5rem;
						margin-right: 0.25rem;
					}
				}

				.collect-number {
					margin-left: 2.5rem;
					font-size: 1.125rem;
					font-family: @font-family;
					font-weight: 400;
					display: flex;
					align-items: center;
					color: #999999;

					.icon-shoucang {
						font-size: 1.5rem;
						font-weight: 600;
						margin-right: 0.25rem;
					}
				}
			}

			.button {
				margin-top: 1.5rem;

			}
		}

		.ad {
			margin-top: 1.125rem;
		}

		.detail-body {
			background: #F5F5F5;
			border-radius: 1.125rem;
			width: 100%;
			flex: 1;
			overflow: auto;
			margin-top: 1.125rem;
			padding: 1.25rem;
			box-sizing: border-box;

			.tag-list {
				margin-left: -0.3125rem;
				margin-right: -0.3125rem;

				.detail-tag {
					display: inline-block;
					margin-left: 0.3125rem;
					margin-right: 0.3125rem;

				}

			}

			.info-list {
				margin-top: 0.25rem;
				border-top: 1px solid #E5E5E5;
				border-bottom: 1px solid #E5E5E5;
				padding-top: 1.125rem;
				box-sizing: border-box;

				.info-item {
					margin-bottom: 1.125rem;
					display: flex;
					justify-content: space-between;

					.item-lable {
						font-size: 1.125rem;
						font-family: @font-family;
						font-weight: 400;
						color: #999999;
					}

					.item-value {
						font-size: 1.125rem;
						font-family: @font-family;
						font-weight: 400;
						color: #333333;
					}

					.link {
						color: @primary-color;
					}
				}
			}

			.all-text {
				font-size: 1rem;
				font-family: @font-family;
				font-weight: 400;
				color: #999999;
				margin-top: 1.125rem;
				white-space: pre-wrap;
			}
		}

		.order-info-item {
			display: flex;
			line-height: 2.6875rem;
			font-size: 1.125rem;
			font-weight: 500;

			.key {
				color: #999;
			}

			.value {
				color: #333;

				.und-price {
					color: #999;
					text-decoration: line-through;
				}

				.no-price {
					color: @primary-color;
					margin-left: 0.5rem;
				}
			}
		}

		.radio {
			display: flex;
			position: relative;
			line-height: 1.125rem;
			margin-right: 0.625rem;
			cursor: pointer;

			input {
				display: none;

				&:checked+span.radio-on {
					width: 0.5rem;
					height: 0.5rem;
					position: absolute;
					border-radius: 100%;
					background: #FFFFFF;
					top: 0.5rem;
					left: 0.25rem;
					background: #00CC5A;
					transform: scale(0, 0);
					transition: all 0.2s ease;
					transform: scale(1, 1);
					display: inline-block;
				}
			}

			.radio-bg {
				display: inline-block;
				height: 1rem;
				width: 1rem;
				margin-right: 0.3125rem;
				margin-top: 0.25rem;
				border: 1px solid #00CC5A;
				border-radius: 100%;
				cursor: pointer;
				box-sizing: border-box;
				flex-shrink: 0;
			}

			.radio-on {
				display: none;
			}
			.protocol {
				flex: 1;
				span {
					line-height: 1.5625rem;
					font-size: 1.125rem;
					color: #999999;
					&.strong {
						color: #666;
					}
					&.link {
						color: #00CC5A;
					}
				}
			}
		}

	}

	::-webkit-scrollbar {
		width: 4px;
		height: 4px;
		background-color: rgba(0, 0, 0, 0);
	}

	::-webkit-scrollbar-track {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0);
	}

	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: #DBDBDB;
	}
</style>
