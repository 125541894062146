<template>
	<div class="containers-null">
		<div>
			<img src="@/assets/null.svg" alt="" srcset="" class="null-img">
			<div class="title">没有找到符合条件的结果</div>
			<div class="info">你可以换一个词重新搜索</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'null-data'
	}
</script>

<style lang="less" scoped>
	@import url("../../index.less");
	.containers-null{
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100vh;
		user-select: none;
		.null-img{
			width: 18.5rem;
		}
		.title{
			font-size: 1.75rem;
			font-family:@font-family;
			font-weight: 400;
			color: #333333;
			text-align: center;
			margin-top: 1.1875rem;
			width: 100%;
		}
			
		.info{
			height: 2.0625rem;
			font-size: 1.5rem;
			font-family:@font-family;
			font-weight: 400;
			color: #999999;
			text-align: center;
			width: 100%;
			line-height: 2.0625rem;
		}
	}
</style>