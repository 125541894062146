
<template>
	<div class="filter-panel">
		<div class="filter-title">分类</div>
		<div class="all-class">
			<ty-checkbox :for="item.id" v-model="item.checked" class="class-item" v-for="(item,index) in allArray" :key="item.for"
			v-show="item.pid==pid"
			>{{item.name}}</ty-checkbox>
		</div>
		<div class="filter-title">标签</div>
		<div class="all-search">
			<ty-search class="ty-search" @push="pushTag"></ty-search>
			<div class="all-tag">
				<tag class="tag" v-for="(item,index) in tagArray"
				 :key="index"
				 @del="removeTag(index)"
				>{{item.name}}</tag>
			</div>
		</div>
		<div class="filter-title">关键字</div>
		<div class="all-key">
			<ty-input placeholder="请输入关键字（可选）" v-model="key"></ty-input>
		</div>
		<div class="filter-title">免费计划 <ty-switch class="switch" v-model="switchs"></ty-switch></div>
		<div class="all-button">
			<ty-button @click="saveForm()">确定</ty-button>
			<ty-button class="reset" :plain="true" @click="resetForm()">重置</ty-button>	
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FilterPanel'
	}
</script>
<script setup>
import { onMounted, ref ,getCurrentInstance,watch} from 'vue';
	const allArray = ref([]);//全部类型
	const key=ref('');//搜索关键词
	const switchs =ref(false);
	const _this = getCurrentInstance().proxy;
	const tagArray= ref([]);//全部标签
	const emit = defineEmits(['save','reset']);
	const props = defineProps(['defaultKey','pid']);
	const defaultType = ref(null)
	
	watch(
	  () => defaultType.value,
	  (newVal, oldVal) => {
			console.log(newVal, oldVal)
		if(newVal==null){
			allArray.value=allArray.value.map((res)=>{
				let obj = res;
				obj.checked=false;
				return obj;
			})
		}else{
			allArray.value=allArray.value.map((res)=>{
				let obj = res;
				console.log(res.id==newVal)
				if(res.id==newVal){
					obj.checked=true;
				}else{
					obj.checked=false;
				}
				return obj;
			})
		}
	});
	
	/**
	 * 删除标签
	 */
	const removeTag=(index)=>{
		tagArray.value.splice(index,1);
	};
	/**
	 * 追加标签
	 */
	const pushTag=(e)=>{
		tagArray.value.push(e);
	};
	
	//查询分类
	const find_Type = () =>{
		_this.$request({
			urlName:'index_categories',
			
		}).then(res=>{
			let list=res.data.map((res)=>{
				let obj = res;
				obj.checked=false;
				return obj;
			})
			allArray.value=list;
		})
	}
	/**
	 * 保存
	 */
	const saveForm=()=>{
		let form={
			keyword:key.value,
			tags:tagArray.value.map(res=>res.id).join(','),
			categories:allArray.value.filter(res=>res.checked==true).map(res=>res.id).join(','),
			free:switchs.value?1:0
		};
		emit('save',form);
	};
	// 接收搜索框的关键字
	const changeKeyword = (keyword) => {
		key.value = keyword;
	}
	// 接收分类
	const changeCate = (type) => {
		console.log(type);
		defaultType.value = type;
	}
	// 清空标签
	const clearTag = () => {
		tagArray.value = [];
	}
	// 重置免费计划
	const resetSwitch =() => {
		switchs.value=false;
	}
		
	/**
	 * 重置表单
	 */	
	const resetForm=()=>{
		key.value='';//搜索关键词
		tagArray.value=[];//清空标签
		defaultType.value = null;
		allArray.value=allArray.value.map((res)=>{
			let obj = res;
			obj.checked=false;
			return obj;
		})
		switchs.value=false;
		emit('reset',{
			keyword:'',
			tags:'',
			categories:'',
			free:''
		});
	}	
		
	onMounted(()=>{
		find_Type();
	})
	defineExpose({
    changeKeyword,
    changeCate,
    clearTag,
    resetSwitch,
	})
	
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.filter-panel{
		width: 30rem;
		padding: 1.75rem 0px 2.5rem 2rem;
		box-sizing: border-box;
		.filter-title{
			font-size: 1.25rem;
			font-family:@font-family;
			font-weight: 400;
			color: #333333;
			user-select: none;
			display: flex;
			align-items: center;
		}
		.all-class{
			margin-top: 1rem;
			margin-bottom: 0.1875rem;
			.class-item{
				width: 7rem;
				margin-right: 0.875rem;
				display: inline-block;
				margin-bottom: 0.75rem;
			}
		}
		.all-search{
			margin-top: 0.5rem;
			.ty-search{
				margin-right: 2rem;
			}
			.all-tag{
				margin-top: 0.875rem;
				max-height: 17.5rem;
				overflow: auto;
				.tag{
					display: inline-block;
					margin-right: 1.75rem;
					margin-bottom: 1.25rem;
				}
			}
		}
		.all-key{
			margin:0.5rem 2rem 1.125rem 0px;
		}
		.switch{
			margin-left: 0.5rem;
		}
		.all-button{
			margin-top: 1.125rem;
			display: flex;
				
			.reset{
				margin-left: 1.125rem;
			}
		}
	}
	
	::-webkit-scrollbar {
		width:4px;
		height: 4px;
		background-color: rgba(0, 0, 0, 0);
	}
	
	::-webkit-scrollbar-track {
		border-radius:4px;
		background-color: rgba(0, 0, 0, 0);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: #DBDBDB;
	}
</style>