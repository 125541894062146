<template>
	<div class="containers-media" v-if="node">
		<template v-for="(item,index) in node" :key="item.id">
			<detail-media-img  v-if="item.type=='img'" v-show="index==action" :imgSrc="item.data" :ref="setRef"></detail-media-img>
			<detail-media-video v-if="item.type=='video' && index==action"  :video-src="item.data.url" :ref="setRef"></detail-media-video>
			<detail-media-model v-if="item.type=='fbx'" v-show="index==action" :url="item.data.fbx" 
			:config="item.data.preview" :ref="setRef">
			</detail-media-model>
			<detail-media-sequence  v-if="item.type=='sequence'" v-show="index==action" :ref="setRef"
			:config="item.data.sequence"
			></detail-media-sequence>
		</template>
	</div>
</template>
<script>
	export default {
		name: 'detail-media'
	}
</script>
<script setup>
	import {ref,toRefs} from 'vue';
	let props = defineProps(['node','action']);
	const {
		action
	} = toRefs(props);
	const boxRefs = ref([]);
	const setRef = (el) => {
	  if (el) {
	    boxRefs.value.push(el);
	  }
	};
	//全屏
	const fullDet = ()=>{
		boxRefs.value[action.value].toggleFullScreen();
	}
	//重置
	const resetDet=()=>{
		boxRefs.value[action.value].resetCamera();
	}
	defineExpose({fullDet,resetDet});
	
</script>
<style lang="less" scoped>
	.containers-media{
		height: 100vh;
		width: 100%;
	}
</style>