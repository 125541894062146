//https://www.apifox.cn/apidoc/shared-4b942f37-5234-4d5f-85b6-674b6fb02a63 3dcs-v2

// 账号接口
export default {
  account_getFavorites:{url:'/api/account/getFavorites',method:'GET'},//收藏列表
  account_getOrders:{url:'/api/account/getOrders',method:'GET'},//已购模型
  account_isFavorite:{url:'/api/account/isFavorite',method:'GET'},//获取用户是否收藏模型
  account_toggleFavorite:{url:'/api/account/toggleFavorite',method:'GET'},//收藏/取消收藏模型
  account_heartBeat: {url: process.env.NODE_ENV === 'production' ? 'https://meta.taiyi.cn/prod/union-user-api/heart/beat' : 'https://mb.cbm.ink/union-user-api/heart/beat', method: 'GET'} ,// 检测localStorage的token是否有效
  account_isLogin:{url:'/api/account/isLogin',method:'GET'},//判断用户是否已经登录
}
