<template>
	<router-view />
</template>

<script>
	export default {
		name: 'App',
		components: {	
		}
	}
</script>


<style lang="less">
	@import url("assets/font/iconfont.css");
	@import url("views/index.less");
	input::placeholder {
		font-family: @font-family;
		user-select: none;
	}
	* {
		padding: 0px;
		margin: 0px;
		
	}
	::-webkit-scrollbar {
		width:8px;
		height: 8px;
		background-color: rgba(0, 0, 0, 0);
	}
	
	::-webkit-scrollbar-track {
		border-radius:8px;
		background-color: rgba(0, 0, 0, 0);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: #DBDBDB;
	}
</style>
