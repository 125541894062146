<template>
	<div class="containers-tabs">
		<div class="tabs-item " :class="index==modelValue?'tabs-item-action':''" v-for="(item,index) in options"
		:key="index"
		@click="toggleOptions(index)">
			{{item}}
		</div>
	</div>
</template>
<script>
	export default {
		name: 'my-tabs'
	}
</script>
<script setup>
import { ref } from 'vue';
defineProps(['options','modelValue']);
const emit = defineEmits(['update:modelValue'])
const toggleOptions=(index)=>{
	emit('update:modelValue', index)
}
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.containers-tabs {
		display: inline-flex;
		user-select: none;
		transition: @animation-time all;
		.tabs-item {
			padding: 0.875rem 2.4375rem;
			font-size: 1.75rem;
			font-family: @font-family;
			font-weight: 400;
			color: #333333;
			border-radius: 2.25rem;
			box-sizing: border-box;
			cursor: pointer;
			transition: @animation-time all;
		}
		.tabs-item-action {
			background: #333333;
			color: #fff;
			font-weight: 600;
			transition: @animation-time all;	
		}
	}
</style>
