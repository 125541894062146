<template>
	<div class="containers">
		<ty-nav :fade="false" :is-help="true"></ty-nav>
		<div class="layout">
      <help-left-section active="常见问题" />
      <div class="right-section">
        <v-md-preview :text="mdText"></v-md-preview>
      </div>
    </div>
	</div>
</template>
<script setup>
  import {ref} from "vue";
  const mdText = ref('');
  const readFile = (filePath) => {
		var xhr= new XMLHttpRequest(),
		method = "GET",
		url = filePath;

		xhr.open(method, url, true);
		xhr.onreadystatechange = function () {
			if(xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				mdText.value = xhr.responseText;
			}
		}
		xhr.send();
	};
	readFile('./qa.md');
</script>
<style lang="less" scoped>
	@import url("../index.less");
  @import url("../markdown.less");

	.containers {
		.layout {
      display: flex;
			box-sizing: border-box;
			min-height: 100vh;
			padding-bottom: 11.375rem;
      margin-top: 4.625rem;
      .left-section {
        width: 19.5rem;
        padding-top: 2.0625rem;
        flex-shrink: 0;
        text-align: center;
        color: #999;
        border-right: 1px solid #EFEFEF;
        div {
          line-height: 3.125rem;
          cursor: pointer;
          &:hover {
            color: #333;
          }
          &.active {
            position: relative;
            color: #333;
            &:after {
              content: '';
              position: absolute;
              left: 0;
              top: .375rem;
              bottom: 0;
              width: .25rem;
              height: 2.375rem;
              background: #333;
            }
          }
        }
      }
      .right-section {
        flex: 1;
      }
		}
	}
</style>
