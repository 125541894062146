<template>
	<div class="containers" >
		<div class="home" @click="home()">返回首页</div>
		<div class="media" v-if="mediaList!=null">
			<Transition name="slide-fade">
				<detail-tabs v-show="tip=='non-full'" class="detail-tabs" v-model="mediaIndex" :node="mediaList"></detail-tabs>
			</Transition>
			<Transition name="bounce">
				<tool-tip v-if="detailDB!=null" v-show="mediaList[mediaIndex].type=='fbx'" v-model="tip" @full="fullDet" @reset="resetDet"></tool-tip>
			</Transition>
			<detail-media ref="mediaRef" :node="mediaList" :action="mediaIndex" ></detail-media>
		</div>
		
		<div class="detail" v-show="tip=='non-full'">
			<introduce v-if="detailDB!=null" :node="detailDB"></introduce>
		</div>
		
		<confirm ref="buySuccess">
			<div class="buy-success">
				<img src="@/assets/icon/success-icon.svg" />
				<div class="main-text">购买成功</div>
				<div class="sub-text">您可在当前页下载已购买的模型文件</div>
			</div>
		</confirm>
	</div>
</template>

<script setup>
	import {
		ref,
		getCurrentInstance,
		onMounted,
		onUnmounted,
		nextTick
	} from 'vue';
	const mediaIndex = ref(0); //当前选中媒体对象 索引
	const buySuccess =ref();// 确认订单
	const tip = ref('non-full'); //全屏状态
	const detailDB = ref(null); // 详情数据
	const mediaList = ref([]);//媒体数组
	
	
	const mediaRef = ref(); //refs
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	const _this = getCurrentInstance().proxy;
	const cdn = ref(_this.$site.cdn_url); //cdn
	// 获取当前路由
	const route = useRoute();
	const router = useRouter();

	/**
	 * 全屏切换
	 */
	const fullDet = () => {
		mediaRef.value.fullDet();
	}
	/**
	 * 重置
	 */
	const resetDet = () => {
		mediaRef.value.resetDet();
	}
	
	
	/**
	 * 返回首页
	 */
	const home = () => {
		//router.go(-1);
		router.push({
			path: '/home'
		});
	}
	/**
	 * 查询详情
	 */
	const find_Detail = (id) => {
		_this.$request({
			urlName: 'index_getMoxing',
			data: {
				id: id
			}
		}).then(res => {
			detailDB.value = res.data;
			mediaList.value = [];
			//赋值空 
			//拼接模型 
			res.data.preview_fbx.map(res => {
				if(res.type && res.type=='sequence'){
					//序列帧
					mediaList.value.push({
						type: 'sequence',
						data: res,
						id:onlyid()
					})
				}else{
					//3d模型
					mediaList.value.push({
						type: 'fbx',
						data: res,
						id:onlyid()
					})
				}
			})
			//拼接视频 
			res.data.images_videos.map(res => {
				mediaList.value.push({
					type: 'video',
					data: {
						url: cdn.value + res,
						poster: ''
					},
					id:onlyid()
				})
			})
			//拼接图片 
			res.data.images_pics.map(res => {
				mediaList.value.push({
					type: 'img',
					data: cdn.value + res,
					id:onlyid()
				})
			});

		})
		
		
	}
	
	let urlAlphabet =
		'useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict';
	let onlyidHistory = []; //唯一id 历史记录
	
	/**
	 * 生成唯一 id
	 */
	const onlyid = (size = 21)=>{
		let id = ''
		let i = size
		while (i--) {
			id += urlAlphabet[(Math.random() * 64) | 0]
		}
		let index = onlyidHistory.findIndex(res => res == id);
		if (index == -1) {
			onlyidHistory.push(id);
			return id
		} else {
			onlyid();
		}
	}
	onMounted(() => {
		let id = route.query.id ? route.query.id: route.params.modelId;
		find_Detail(id);
		if(route.query && route.query.pay){
			if(route.query.pay==='done'){
				//支付成功
				buySuccess.value.inquiry({
					type: 'messageBox'
				});
			}else{
				//支付失败
			}
		}
	})

</script>

<style lang="less" scoped>
	@import url("../index.less");
	.bounce-enter-active {
	  animation: bounce-in @animation-time;
	}
	.bounce-leave-active {
	  animation: bounce-in @animation-time reverse;
	}
	@keyframes bounce-in {
	  0% {
	    transform: scale(0);
	  }
	  50% {
	    transform: scale(1.15);
	  }
	  100% {
	    transform: scale(1);
	  }
	}
	
	.slide-fade-enter-active {
	  transition: all @animation-short-time linear;
	}
	
	.slide-fade-leave-active {
	  transition: all @animation-short-time linear;
	}
	
	.slide-fade-enter-from,
	.slide-fade-leave-to {
	  transform: translateX(-20px);
	  opacity: 0;
	}

	
	// end 动画
	.containers {
		display: flex;
		height: 100vh;
		width: 100%;
		position: relative;
		overflow: hidden;
		.home {
			position: absolute;
			width: 6.25rem;
			height: 2.875rem;
			background: rgba(0, 0, 0, 0.2);
			border-radius: 0.75rem;
			top: 3.5625rem;
			left: 5%;
			z-index: 99;
			font-size: 1.125rem;
			font-family: @font-family;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			line-height: 2.875rem;
			user-select: none;
			cursor: pointer;
		}

		.media {
			flex: 1;
			position: relative;
			overflow: hidden;
			display: flex;
			align-items: center;
			.detail-tabs {
				position: absolute;
				z-index: 99;
				
				left: 6.25%;
			}
		}
		.detail {
			background: #FFFFFF;
			box-shadow: 0px 0px 0.5rem 0px rgba(239, 239, 239, 0.49);
			border-radius: 1.125rem 0px 0px 1.125rem;
			height: inherit;
			width: 26.625rem;
		}
		.buy-success {
			text-align: center;
			img {
				width: 3.75rem;
				margin-top: 1.5rem;
			}
			.main-text {
				margin-top: 1.125rem;
				margin-bottom: 0.5625rem;
				font-size: 1.25rem;
				font-weight: 600;
				color: #333;
				line-height: 1.75rem;
			}
			.sub-text {
				font-size: 1.125rem;
				font-weight: 500;
				color: #666;
				line-height: 1.5625rem;
			}
		}
	}
</style>
