<template>
	<div class="rotating-banner">
		<swiper :modules="modules" 
		    :navigation="{
				prevEl:'#up',
				nextEl:'#next'
			}"
			:allowTouchMove="false"
			:pagination="{ 
				clickable: true,
				bulletActiveClass:'ty-swiper-action',
				}"
			:autoplay="{delay:10000,disableOnInteraction: false}"
			>
			<swiper-slide v-for="item in bannerArray" class="slide" :key="item.image">
				<img draggable="false" :src="cdn+item.image" decoding="async"  loading="lazy"
				 @click="jump(item.link)"
				 />
				<fbx-preview :config="item.preview_config" :url="item.preview_file" :encoded="true" class="fbx">
				</fbx-preview>
			</swiper-slide>
			<img src="@/assets/up.svg" id="up" draggable="false"/>
			<img src="@/assets/next.svg" id="next" draggable="false"/>
		</swiper>
		
	</div>
</template>
<script>
	// Import Swiper Vue.js components
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue';
	// Import Swiper styles
	import 'swiper/css';
	import "swiper/css/navigation";
	import "swiper/css/pagination";
	import {
		Pagination,
		Navigation,
		Autoplay
	} from "swiper";

	import {
		onMounted,
		getCurrentInstance,
		ref
	} from 'vue';
	export default {
		name: 'rotating-banner',
		components: {
			Swiper,
			SwiperSlide

		},
		setup() {
			const _this = getCurrentInstance().proxy;
			const cdn = ref(_this.$site.cdn_url);//cdn
			const bannerArray = ref([]);
			//查询轮播
			const find_Banner = () => {
				_this.$request({
					urlName: 'index_banner'
				}).then(res => {
					let data = res.data.map(res => {
						let obj = res;
						if (obj.preview_config != null && obj.preview_config != '') {
							obj.preview_config = JSON.parse(obj.preview_config.replace(/\\"/g, '"'));
						} else {
							obj.preview_config = null;
						}
						return obj;
					});
					bannerArray.value = data;
				})
			};
			const jump = (link) =>{
				if(link!=null && link!=''){
					window.location.href=link;
				}
			}
			
			onMounted(() => {
				find_Banner();
			});

			return {
				bannerArray,
				cdn,
				modules: [Pagination, Autoplay,Navigation],
				jump
			};
		},
	};
</script>
<style>
	.ty-swiper-action {
		background-color: #fff;
		opacity: 1;
	}
	.swiper-pagination{
		left:4.375rem !important;
		text-align: left;
		bottom:4.125rem !important;
	}
	.swiper-pagination-bullet{
		margin-left: 0.625rem !important;
		margin-right: 0.625rem !important;
		width: 0.75rem !important;
		height: 0.75rem !important;
	}
	
</style>
<style scoped lang="less">
	#up{
		position: absolute;
		top: 28.0625rem;
		width: 4.25rem;
		height: 4.25rem;
		z-index: 999;
		left:5rem;
		cursor: pointer;
		
	}
	#next{
		position: absolute;
		top: 28.0625rem;
		width: 4.25rem;
		height: 4.25rem;
		z-index: 999;
		right:5rem;
		cursor: pointer;
	}
	.rotating-banner {
		.slide {
			position: relative;
		}

		img {
			width: 100%;
			height: 60.0625rem;
			user-select: none;
		
		}

		.fbx {
			position: absolute;
			top: 0px;
			width: 50vw;
			z-index: 9;
			right: 0px;
			height: 60.0625rem;
		}
	}
</style>
