<template>
	<div class="popular-array">
		<div class="popular-item" v-for="(item,index) in array" :key="index"
		@click="togglePopular(item,index)">
			{{item}}
		</div>
	</div>
</template>

<script>
	export default {
		name: 'popular-array'
	}
</script>
<script setup>
	defineProps(['array']);
	const emit = defineEmits(['change']);
	const togglePopular=(lable,index)=>{
		emit('change',{lable,index});
	};
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.popular-array{
		width: 9.375rem;
		padding: 0.625rem 0px;
		.popular-item{
			margin: 0.125rem 0.375rem;
			height: 2.5rem;
			cursor: pointer;
			line-height: 2.5rem;
			padding-left: 0.75rem;
			box-sizing: border-box;
			font-weight: 400;
			transition: @animation-time all;
			color: #666666;
			font-family:@font-family;
			border-radius: 0.875rem;
		}
		.popular-item:hover {
			color: #333333;
			background: #EFEFEF;
		}
	}
</style>