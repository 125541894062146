<template>
	<div class="containers-media-img">
		<img :src="imgSrc" :width="imgWidth" :height="imgHeight" decoding="async">
	</div>
</template>

<script>
	export default {
		name: 'detail-media-img'
	}
</script>
<script setup>
	defineProps({
		//图片高度
		imgHeight:{
			type: Number,
			default:null,
			required:false
		},
		//媒体类型
		mediaType:{
			type:String,
			default:'img',
			required: true
		},
		//图片路径
		imgSrc:{
			type:String,
			required: true
		}
	})
	
</script>

<style lang="less" scoped>
	.containers-media-img{
		user-select: none;
		background-color: #000000;
		// background: radial-gradient(center , circle cover , rgb(255, 255, 255) 0% ,  rgb(224, 227, 234) 100%);
		// background: -o-radial-gradient(center , circle cover , rgb(255, 255, 255) 0% ,  rgb(224, 227, 234) 100%);
		// background: -ms-radial-gradient(center , circle cover , rgb(255, 255, 255) 0% ,  rgb(224, 227, 234) 100%);
		// background: -moz-radial-gradient(center , circle cover , rgb(255, 255, 255) 0% ,  rgb(224, 227, 234)  100%);
		// background: -webkit-radial-gradient(center , circle cover ,rgb(255, 255, 255) 0% , rgb(224, 227, 234) 100%);
		height: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		img{
			max-width: 100%;
			max-height: 100%;
		}
	}
</style>