<template>
	<div class="containers-nav-login" >
		<div v-if="isLogin" class="user" ref="menu" >
			<img v-if="user" :src="user.avatar || defaultAvatar" @click="jump('/my',0)" >
			<span v-if="user" @click="jump('/my',0)">{{user.nickname}}</span>
			<ty-nav-menu class="menu" ></ty-nav-menu>
		</div>
		<button class="login-button" v-else @click="userLogin()">登录</button>
	</div>
</template>

<script>
	export default {
		name: 'ty-nav-login'
	}
</script>
<script setup>
import { onMounted, ref, computed, onUnmounted, } from 'vue';
import { useStore } from 'vuex';
import defaultAvatar from '@/assets/default-avatar.png';
const store = useStore();
import { useRouter} from 'vue-router';
const user = computed(() => {
	return store.state.userInfo ? store.state.userInfo : (localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null)
});//用户信息

const isLogin = computed(() => store.state.isLogin);//是否已登录
const showMenu = ref(false);
const menu = ref();
const router = useRouter();
const jump = (url,q)=>{
	showMenu.value = false;
	router.push({ path: url,  query: {index:q} });
}	
const userLogin=()=>{
	if(process.env.NODE_ENV === 'development'){
		window.location.replace(`https://3dcs.taiyi.cn/launcher-web/#/?redirect_uri=${encodeURIComponent(window.location.href)}`)
			
	}else{
		window.location.replace(`https://3d.taiyi.cn/prod/launcher-web/#/?redirect_uri=${encodeURIComponent(window.location.href)}`) ;
	}
}

const bodyCloseMenus = (e) => {
	if(menu.value &&!menu.value .contains(e.target)){
		showMenu.value = false;
	}
}
onUnmounted(() => {
	document.removeEventListener("click", bodyCloseMenus)
})
onMounted(async ()=>{
	await store.dispatch('checkUserToken');
	if (!isLogin.value) {
		store.dispatch('login').then(() => {
			user.value = JSON.parse(localStorage.getItem("userInfo"));
		});
	}
	document.addEventListener("click", bodyCloseMenus)
})
</script>

<style lang="less" scoped>
	@import url("../../index.less");
	.containers-nav-login{
		
		.login-button{
			width: 10.75rem;
			height: 3.125rem;
			background: @primary-color;
			border-radius: 0.875rem;
			border: none;
			cursor: pointer;
			font-size: 1.25rem;
			font-family: @font-family;
			font-weight: 500;
			color: #FFFFFF;
		}
		.user{
			padding-left: 4.75rem;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			font-size: 1.25rem;
			font-family: @font-family;
			font-weight: 500;
			color: #FFFFFF;
			cursor: pointer;
			height:5rem;
			position: relative;
			img{
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				margin-right: 0.875rem;
			}
			.menu{
				position: absolute;
				top: 5rem;
				left: 0px;
				display: none;
			}
			&:hover {
				.menu{
					display: block;
				}
			}
		}
	}
</style>