<template>
	<div class="containers-nav-search">
		<input type="text" placeholder="搜索" :value="modelValue" @input="onInput" 
		@keyup.enter="onenter"
		/>
		<div class="search-icon" @click="onenter">
			<img src="@/assets/icon/nav-search-icon.svg" />
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ty-nav-search'
	}
</script>
<script setup>
	defineProps(['modelValue']);
	const emit = defineEmits(['update:modelValue','input','enter'])
	const onInput=(e)=>{
		emit('update:modelValue', e.target.value)
		emit('input', e)
	}
	//回车
	const onenter = (e)=>{
		emit('enter', e)
	}
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.containers-nav-search{
		width: 51.25rem;
		height: 2.5rem;
		border-radius: 2.125rem;
		border: 1px solid rgba(255,255,255,0.6);
		position: relative;
		input{
			width: inherit;
			height: inherit;
			border: none;
			outline: none;
			border-radius: inherit;
			background-color: inherit;
			font-family: @font-family;
			font-size: 1rem;
			font-weight: 400;
			color: #ffffff;
			padding-left: 1.5rem;
			padding-right: 4.5rem;
			box-sizing: border-box;
		}
		input::placeholder {
			color: #ffffff;
			font-size: 1rem;
		}
		.search-icon{
			width: 4.5rem;
			height: inherit;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			right: 0px;
			top: 0px;
			img{
				width: 1.3125rem;
				height: 1.3125rem;
			}
		}
	}
</style>