<template>
	<div class="containers-preview" >
		<tai-fbx-preview 
		v-if="url"
		ref="fbxRefx"
		:key="url"
		:encoded="true"
		:url="cdn+url"
		:fovSize="config && config.fovSize!=null ? config.fovSize :15"
		:modelPosition="config && config.modelPosition!=null? config.modelPosition:0"
		:background="config && config.background!=null ? config.background:0"
		:enableZoom="config && config.enableZoom!=null ? config.enableZoom:true"
		:enableRotate="config && config.enableRotate!=null ? config.enableRotate:true"
		:enableControl="config && config.enableControl!=null ? config.enableControl:true"
		:autoRotate="config && config.autoRotate!=null ? config.autoRotate:true"
		:autoRotateSpeed="config && config.autoRotateSpeed!=null ? config.autoRotateSpeed:1"
		:openShadow="config && config.openShadow!=null ? config.openShadow:false"
		:shadowColor="config && config.shadowColor!=null ? config.shadowColor:0xAAAAAA"
		:ambientPosition="config && config.ambientPosition!=null ? config.ambientPosition:'10,10,20'"
		:ambientLightIntensity="config && config.ambientLightIntensity!=null ? config.ambientLightIntensity:2"
		:ambientLightColor="config && config.ambientLightColor!=null ? config.ambientLightColor:0x000000"
		:dirLightIntensity="config && config.dirLightIntensity!=null ? config.dirLightIntensity:0.5"
		:dirLightColor="config && config.dirLightColor!=null ? config.dirLightColor:0xffffff"
		:dirLightPosition="config && config.dirLightPosition!=null ? config.dirLightPosition:'10,10,20'"
		:spotLightColor="config && config.spotLightColor!=null ? config.spotLightColor:0xffffff"
		:spotLightPosition="config && config.spotLightPosition!=null ? config.spotLightPosition:'2,0.5,0'"
		:spotLightIntensity="config && config.spotLightIntensity!=null ? config.spotLightIntensity:2"
		:hemiLightIntensity="config && config.hemiLightIntensity!=null ? config.hemiLightIntensity:1"
		:hemiLightSkyColor="config && config.hemiLightSkyColor!=null ? config.hemiLightSkyColor:0xffffff"
		:hemiLightGroundColor="config && config.hemiLightGroundColor!=null ? config.hemiLightGroundColor:0x444444"
		:hemiLightPosition="config && config.hemiLightPosition!=null ? config.hemiLightPosition:'10,10,20'"
		:openToolbar="config && config.openToolbar!=null ? config.openToolbar:false"
		></tai-fbx-preview>
	</div>
</template>
<script>
	export default {
		name: 'fbx-preview'
	}
</script>
<script setup>
	import { ref ,getCurrentInstance,defineExpose, nextTick, onUnmounted,defineAsyncComponent } from 'vue';
	//创建异步组件
	const taiFbxPreview = defineAsyncComponent(() =>
	  import('@/components/taiyi-fbx-previewer/index.vue')
	)
	defineProps(['config','url','encoded']);
	const _this = getCurrentInstance().proxy;
	const cdn = ref(_this.$site.cdn_url);//cdn
	const fbxRefx = ref();//refs
	// 重置模型
	const resetCamera = () => {
	  fbxRefx.value.resetModel();
	}
	// 刷新模型尺寸以适应父组件
	const toggleFullScreen = () => {
		 nextTick(()=>{
			 fbxRefx.value.toggleFullScreen();
		 })
	}
	
	defineExpose({resetCamera,toggleFullScreen});
	
</script>
