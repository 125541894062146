<template>
	<div>
		<div class="containers-tag">
			<slot></slot>
			<span class="iconfont icon-guanbi" @click="tapRemove()"></span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'tag'
	}
</script>
<script setup>
	const emit = defineEmits(['del']);
	const tapRemove=()=>{
		emit('del',{});
	}
	
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.containers-tag{
		border-radius: 0.5rem;
		border: 1px solid @primary-color;
		height: 2.5rem;
		box-sizing: border-box;
		font-family: @font-family;
		font-size: 1.125rem;		
		font-weight: 400;
		color: @primary-color;
		user-select: none;
		display: flex;
		align-items: center;
		padding: 0px 0.5rem;
		justify-content: space-between;
		.icon-guanbi{
			cursor: pointer;
		}
	}
</style>