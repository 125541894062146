<template>
	<div class="containers-nav" :style="'background: rgba(3, 3, 3,'+bg+');'" :class="isMy?'my-nav':''">
		<router-link class="nav-logo" to="/"  draggable="false">
			<img src="@/assets/logo.svg" role="img" draggable="false"/>
		</router-link>
		<span class="home" v-if="isMy">个人中心</span>
		<span class="home" v-if="isHelp">帮助中心</span>
		<div v-if="!isMy && !isHelp" class="links">
			<div class="link">模型资产
				<div>
					<span class="current-link" v-for="(item,index) in options" :key="index" @click="tapModel(index)">{{item}}</span>
				</div>
			</div>
			<div class="link">
				应用场景
				<div>
					<a class="current-link" href="https://taiyi.cn/" target="_blank">太一灵境</a>
					<a class="current-link" href="https://taiyi.cn/meta-tang" target="_blank">大唐灵境</a>
					<a class="current-link" href="https://taiyi.cn/cloud-club" target="_blank">太一云境</a>
					<a class="current-link" href="https://taiyi.cn/star-one" target="_blank">StarOne超级岛</a>
				</div>
			</div>
			<div class="link">
				帮助中心
				<div>
					<router-link class="current-link" to="/qa">常见问题</router-link>
					<router-link class="current-link" to="/updateLog">更新日志</router-link>
				</div>
			</div>
		</div>
		<ty-nav-search v-if="!isMy && !isHelp" v-model="keystr" @input="onInput" @enter="onEnter"></ty-nav-search>
		<ty-nav-login></ty-nav-login>
	</div>
</template>

<script>
	export default {
		name: 'ty-nav'
	}
</script>
<script setup>
	import {
		onMounted,
		onUnmounted,
		ref,
		toRefs,
		computed
	} from 'vue';
	import { useRouter } from 'vue-router';
	const router = useRouter();
	const props = defineProps({
		//是否渐变
		fade:{
			type: Boolean,
			default:true,
		},
		//是否是个人中心
		isMy:{
			type: Boolean,
			default:false,
		},
		//是否是个人中心
		isHelp:{
			type: Boolean,
			default:false,
		},
		modelValue:{
			type: Object,
		},
		options:{
			type:Array,
			default:[]
		}
	});
	const emit = defineEmits(['update:modelValue','input','enter','change'])
	const keystr = computed(() => props.modelValue)
	const onInput=(e)=>{
		emit('update:modelValue', e.target.value)
		emit('input', e)
	}
	const onEnter=(e)=>{
		emit('enter', e)
	}
	// 切换选中
	const tapModel=(e)=>{
		emit('change', e)
	}
	const {fade} = toRefs(props);
	
	const bg =fade.value?ref(0):ref(0.7);
	const onScroll = ((res) => {
		let rgb = window.scrollY / 1000;
		if(rgb<=0.7){
			bg.value=rgb;
		}else{
			bg.value=0.7;
		}
	})
	/**
	 * 返回首页
	 */
	const home = () =>{
		router.push({ path: '/home'});
	}
	onMounted(() => {
		if(fade.value)
		{
			window.addEventListener('scroll',onScroll)
		}
		
	})
	onUnmounted(() => {
		if(fade.value){
			window.removeEventListener("scroll", onScroll)
		}
		
	})
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.containers-nav {
		user-select: none;
		width: 100%;
		height: 5rem;
		transition: all 0.3ms;
		backdrop-filter: blur(4px);
		position: fixed;
		top: 0px;
		z-index: 999;
		display: flex;
		justify-content: space-between;
		padding: 0px 4.6875rem;
		box-sizing: border-box;
		align-items: center;
		.nav-logo{
			height: 2.25rem;
			img{
				width: inherit;
				height: inherit;
			}
		}
		.home{
			font-size: 1.25rem;
			font-family:@font-family;
			font-weight: 400;
			color: #FFFFFF;
			margin-left: 2.375rem;
			cursor: pointer;
		}
		.links {
			display: flex;
			color: #fff;
			font-size: 1.25rem;
			.link {
				position: relative;
				height: 5rem;
				margin-left: 4.375rem;
				line-height: 5rem;
				&:hover {
					> div {
						display: block;
					}
				}
				> div {
					display: none;
					position: absolute;
					left: 50%;
					top: 5rem;
					transform: translateX(-50%);
					padding: .75rem .5rem;
					background: #FFFFFF;
					box-shadow: 0 0 0.875rem 0 rgba(0, 0, 0, 0.11);
					border-radius: 1.125rem;
					.current-link {
						display: block;
						width: 11rem;
						height: 3.75rem;
						border-radius: 1.125rem;
						font-weight: 500;
						font-size: 1.125rem;
						color: #333;
						line-height: 3.75rem;
						text-align: center;
						text-decoration: none;
						cursor: pointer;
						&:hover {
							background: #EFEFEF;
						}
					}
				}
			}
		}
	}
	.my-nav{
		.nav-logo{
			display: flex;
			align-items: center;
		}
	}
</style>
