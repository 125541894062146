<template>
	<div class="containers-switch" @click="onSwitch(modelValue)">
		<span class="iconfont" :class="modelValue?'icon-a-kaiguankai':'icon-a-kaiguanguan'"></span>
	</div>
</template>

<script>
	export default {
		name: 'ty-switch'
	}
</script>
<script setup>
	const props = defineProps(['modelValue'])
	const emit = defineEmits(['update:modelValue']);
	const onSwitch = (e) => {
		emit('update:modelValue', !e);
	}
	
</script>

<style lang="less" scoped>
	@import url("../../index.less");
	.containers-switch{
		height: inherit;
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
		.iconfont{
			font-size: 1.5rem;
			transition: all @animation-time;
		}
		.icon-a-kaiguanguan{
			color: #DCDFE6;
		}
		.icon-a-kaiguankai{
			color: @primary-color;
		}
	}
</style>