/**
 * 根据时间戳获取 
 * >date 时间戳
 * >format
	|格式	|含义	|备注								|举例	|
	|yyyy	|年		|									|2017	|
	|M		|月		|不补0								|1		|
	|MM		|月		|									|01		|
	|W		|周		|仅周选择器的 format 可用；不补0		|1		|
	|WW		|周		|仅周选择器的 format 可用				|01		|
	|d		|日		|不补0								|2		|
	|dd		|日		|									|02		|
	|H		|小时	|24小时制；不补0						|3		|
	|HH		|小时	|24小时制							|03		|
	|m		|分钟	|不补0								|4		|
	|mm		|分钟	|									|04		|
	|s		|秒		|不补0								|5		|
	|ss		|秒		|									|05		|
 */
export function getTime(date,format){
	if(date==0){
		return '--';
	}
	let day= new Date(parseInt(date));
	let str = format; //时间格式
	//按顺序处理
	//yyyy
	if (str.indexOf('yyyy') > -1) {
		str = str.replace('yyyy', day.getFullYear());
	}
	//MM
	if (str.indexOf('MM') > -1) {
		let m = day.getMonth() + 1;
		if (m < 10) {
			m = '0' + m;
		}
		str = str.replace('MM', m);
	}
	//M
	if (str.indexOf('M') > -1) {
		let m = day.getMonth() + 1;
		str = str.replace('M', m);
	}
	// WW
	if (str.indexOf('WW') > -1) {
		let w = day.getDay();
		w = '0' + w;
		str = str.replace('WW', w);
	}
	// W
	if (str.indexOf('W') > -1) {
		let w = day.getDay();
		str = str.replace('W', w);
	}
	//dd
	if (str.indexOf('dd') > -1) {
		let d = day.getDate();
		if (d <= 9) {
			d = '0' + d;
		}
		str = str.replace('dd', d);
	}
	//d
	if (str.indexOf('d') > -1) {
		let d = day.getDate();
		str = str.replace('d', d);
	}
	//HH
	if (str.indexOf('HH') > -1) {
		let h = day.getHours();
		if (h < 10) {
			h = '0' + h;
		}
		str = str.replace('HH', h);
	}
	//H
	if (str.indexOf('H') > -1) {
		let h = day.getHours();
		str = str.replace('H', h);
	}
	//mm
	if (str.indexOf('mm') > -1) {
		let m = day.getMinutes();
		if (m < 10) {
			m = '0' + m;
		}
		str = str.replace('mm', m);
	}
	//m
	if (str.indexOf('m') > -1) {
		let m = day.getMinutes();
		str = str.replace('m', m);
	}
	//ss
	if (str.indexOf('ss') > -1) {
		let s = day.getSeconds();
		if (s < 10) {
			s = '0' + s;
		}
		str = str.replace('ss', s);
	}
	//s
	if (str.indexOf('s') > -1) {
		let s = day.getSeconds();
		str = str.replace('s', s);
	}
	return str;
}

// 防抖函数
export const debounce = (func, delay) => { // func 函数 delay间隔时间
  let timer
  return function (...args) {
		return new Promise((resolve) => {
			if (timer) {
				clearTimeout(timer)
			}
			timer = setTimeout(() => {
				resolve(func.apply(this, args))
			}, delay)
		})
  }
}
