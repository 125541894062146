<template>
	<div class="component">
		<router-link class="containers-model" v-if="node" :to="`/detail?id=${node.id}`">
			<span class="model-tag" v-if="node.category">{{node.category.name}}</span>
			<div class="model-media" :class="mediaType=='img'?'model-img':'model-video'">
				<img v-if="mediaType=='img'" :src="cdn+node.images_pics[0]" draggable="false" decoding="async" loading="lazy" />
				<ty-video v-else class="video" :video-src="cdn+node.images_videos[0]"
				:poster="node.images_pics[0]?cdn+node.images_pics[0]:null"
				></ty-video>
			</div>
			<div class="model-title">
				{{node.name}}
			</div>
			<div class="model-money">
				<span v-if="free" class="free">免费计划<span class="underline">¥{{node.price}}</span></span>
				<span class="default" v-else>¥{{node.price}}</span>
			</div>
		</router-link>
		<div class="collection" v-if="isCollect" @click.stop="onCollect(node.id)">
			<span v-if="collect" class="collect"><span class="iconfont icon-yishoucang"></span><span>已收藏</span></span>
			<span v-else class="collect"><span class="iconfont icon-shoucang"></span><span>收藏</span></span>
		</div>
		<confirm ref="login">您尚未登录，请登录后收藏模型</confirm>
	</div>
</template>

<script>
	export default {
		name: 'omit-model'
	}
</script>
<script setup>
let props =defineProps({
		//collect 是否已收藏
		collect: {
		  type: Boolean,
		  default: false,
		  required: true
		},
		// free 是否免费
		free: {
		  type: Boolean,
		  default: false,
		  required: true
		},
		// 图片宽度
		imgWidth:{
			type: Number,
			default:null,
			required:false
		},
		//图片高度
		imgHeight:{
			type: Number,
			default:null,
			required:false
		},
		//媒体类型
		mediaType:{
			type:String,
			default:'img',
			required: true
		},
		//是否显示收藏按钮
		isCollect:{
			type: Boolean,
			default: true,
			required: true
		},
		//模型数据
		node:{
			type:Object,
			required: true
		}
	});
	
	import { useRouter } from 'vue-router';
	import {getCurrentInstance,toRefs,ref,onMounted, computed} from 'vue';
	import { useStore } from 'vuex';
	const store = useStore();
	const login = ref();
	const isLogin = computed(() => store.state.isLogin);//是否已登录
	
	const router = useRouter();
	const jump = (id)=>{
		router.push({ path: '/detail',  query: {id:id} });
	}
	const _this = getCurrentInstance().proxy;
	const cdn = ref(_this.$site.cdn_url);//cdn
	
	const emit = defineEmits(['change'])
	
	/**
	 * 切换收藏
	 */
	const onCollect=(parm)=>{
		if(isLogin.value){
			_this.$request({
				urlName:'account_toggleFavorite',
				data:{
					id:parm
				}
			}).then(res=>{
				emit('change',{id:parm,select:res.data});
			})
		}else{
			login.value.inquiry({
				title:'提示',
				cancelText:'取消',
				identifyText:'去登录',
				identifyEvent:()=>{
					userLogin();
				},
				cancelEvent:()=>{
					
				}
			});
		}
		
	}
	//登录
	const userLogin=()=>{
		if(process.env.NODE_ENV === 'development'){
			window.location.replace(`https://3dcs.taiyi.cn/launcher-web/#/?redirect_uri=${encodeURIComponent(window.location.href)}`)
				
		}else{
			window.location.replace(`https://3d.taiyi.cn/prod/launcher-web/#/?redirect_uri=${encodeURIComponent(window.location.href)}`) ;
		}
	}
	
	onMounted(()=>{
		if (!isLogin.value) store.dispatch('login');
	})
</script>

<style lang="less" scoped>
	@import url("../../index.less");
	.component {
		position: relative;
		.collection{
				position: absolute;
				right: 0;
				top: 220px;
				width: 80px;
				height: 27px;
				background-image: url(@/assets/icon/rectangle.svg);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				position: absolute;
				bottom: 0px;
				right: 0px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 11px;
				font-family: @font-family;
				font-weight: 400;
				color: #FFFFFF;
				cursor: pointer;
				.collect{
					display: flex;
					align-items: center;
					user-select: none;
				}
				.iconfont{
					margin-right: 7px;
					font-weight: 500;
				}
				.icon-shoucang{
					font-size: 19px;
					color: #fff;
					
				}
				.icon-yishoucang{
					font-size: 19px;
					color: #FFB50F;
				}
			}
	}
	.containers-model{
		display: block;
		width: 367px;
		height: 324px;
		background: #FFFFFF;
		box-shadow: 0px 3px 5px 0px rgba(219,219,219,0.4);
		border-radius: 12px;
		transition: all @animation-time;
		position: relative;
		user-select: none;
		text-decoration: none;
		.model-tag{
			width: 60px;
			height: 25px;
			background: rgba(0,0,0,0.1);
			border-radius: 9px;
			position: absolute;
			top: 9px;
			left: 9px;
			font-size: 11px;
			font-family: @font-family;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			line-height: 25px;
			z-index: 9;
		}
		.model-media{
			position: relative;
			width: 367px;
			height: 247px;
			border-radius: 12px;
			overflow: hidden;
			
		}
		.model-img{
			background: radial-gradient(center , circle cover , rgb(255, 255, 255) 0% ,  rgb(223, 225, 226) 100%);
			background: -o-radial-gradient(center , circle cover , rgb(255, 255, 255) 0% ,  rgb(223, 225, 226) 100%);
			background: -ms-radial-gradient(center , circle cover , rgb(255, 255, 255) 0% ,  rgb(223, 225, 226)  100%);
			background: -moz-radial-gradient(center , circle cover , rgb(255, 255, 255) 0% ,  rgb(223, 225, 226)  100%);
			background: -webkit-radial-gradient(center , circle cover ,rgb(255, 255, 255) 0% ,  rgb(223, 225, 226)  100%);
			display: flex;
			justify-content: center;
			align-items: center;
			img{
				max-width: 100%;
				max-height: 100%;
			}
			
		}
		.model-video{
			background: #000;
			display: flex;
			justify-content: center;
			align-items: center;
			.video{
				max-width: 100%;
				max-height: 100%;
			}
			
		}
		.model-title{
			margin-top: 16px;
			margin-left: 9px;
			font-size: 12px;
			font-family: @font-family;
			font-weight: 500;
			color: #333333;
		}
		.model-money{
			margin-left: 9px;
			margin-top: 7px;
			.default{
				font-size: 16px;
				font-family: @font-family;
				font-weight: 600;
				color: #333333;
			}
			.free{
				font-size: 16px;
				font-family: @font-family;
				font-weight: 600;
				color: @primary-color;
				.underline{
					margin-left: 5px;
					font-size: 12px;
					font-family: @font-family;
					font-weight: 400;
					color: #999999;
					text-decoration: line-through;
				}
			}
		}
		
	}
	.containers-model:hover{
		box-shadow: 0px 9px 11px -1px rgba(219,219,219,0.75);
	}
	
</style>