<template>
	<div class="containers-confirm" v-if="show">
		<div class="confirm-block"></div>
		<div class="confirm-body">
			<div class="confirm-page">
				<div v-if="confirmType === 'dialog'" class="page-title">{{titleLable}}</div>
				<div class="page-body">
					<slot></slot>
				</div>
				<div v-if="confirmType === 'dialog'" class="page-button">
					<ty-button :plain="true" class="ty-button" @click.stop="cancel()">{{cancelLable}}</ty-button>
					<ty-button class="ty-button" @click.stop="identify()" :disabled="disabled">{{identifyLable}}</ty-button>
				</div>
				<div v-if="confirmType === 'messageBox'" class="page-button center">
					<ty-button :plain="true" class="ty-button" @click="cancel()">{{identifyLable}}</ty-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'confirm'
	}
</script>
<script setup>
	import { ref } from 'vue';
	defineProps({
		disabled:{
			type: Boolean,
			default:false,
			required:false
		}
	});
	const show = ref(false);
	const titleLable = ref('提示');
	const cancelLable=ref('取消');
	const identifyLable=ref('确认');
	const confirmType=ref('dialog');
	
	let cancelFun,identifyFun;
	
	//取消
	const cancel = () => {
		show.value=false;
		cancelFun();
	}
	//确定
	const identify = ()=>{
		show.value=false;
		identifyFun();
	}
	
	/**
	 * 询问弹框
	 * @param title 标题
	 * @param type 弹窗类型
	 * @param cancelText 取消按钮文本
	 * @param cancelEvent 取消回调事件
	 * @param identifyText 确定按钮文本
	 * @param identifyEvent 确定回调事件
	 */
	const inquiry = ({title,cancelText,cancelEvent,identifyText,identifyEvent, type}) =>{
		show.value = true;
		if (title && typeof title === 'string') {
			titleLable.value = title;
		}
		if (cancelText && typeof cancelText === 'string') {
			cancelLable.value = cancelText;
		}
		if (identifyText && typeof identifyText === 'string') {
			identifyLable.value = identifyText;
		}
		if (type && typeof type === 'string') {
			confirmType.value = type;
		}
		if (cancelEvent && typeof cancelEvent === 'function') {
			cancelFun = cancelEvent;
		}
		if (identifyEvent && typeof identifyEvent === 'function') {
			identifyFun = identifyEvent;
		}
	}
	defineExpose({inquiry});
	
</script>
<style lang="less" scoped>
@import url("../../index.less");
.containers-confirm{
	.confirm-block{
		width: 100vw;
		height: 100vh;
		background: rgba(0,0,0,0.6);
		position: fixed;
		z-index: 999;
		top: 0px;
		left: 0px;
	}
	.confirm-body{
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: 1000;
		top: 0px;
		display:flex;
		left: 0px;
		justify-content: center;
		align-items: center;
		.confirm-page{
			width: 35rem;
			background: #FFFFFF;
			border-radius: 0.625rem;
			.page-title{
				height: 3.75rem;
				padding: 0px 2.375rem;
				box-sizing: border-box;
				font-size: 1.25rem;
				font-family: @font-family;
				font-weight: 600;
				color: #333333;
				line-height: 3.75rem;
				border-bottom: 1px solid #EFEFEF;
			}
			.page-body{
				padding: 1.75rem 2.375rem;
				box-sizing: border-box;
				font-size: 1.125rem;
				font-family: @font-family;
				font-weight: 500;
				color: #666666;
				
			}
			.page-button{
				margin-top: 1.5rem;
				margin-bottom: 1.875rem;
				display: flex;
				justify-content: end;
				&.center {
					justify-content: center;
						.ty-button{
						margin-right: 0;
					}
				}
				.ty-button{
					margin-right: 1.5rem;
				}
			}
		}
	}
}	
</style>