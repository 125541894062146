import { createStore } from 'vuex'
import {
	requestHttp
} from '@/request/index';
import { debounce } from '@/utils'

// 创建一个新的 store 实例
const store = createStore({
  state () {
    return {
      isLogin: localStorage.getItem("ba-user-token"),
      userInfo: null
    }
  },
  mutations: {
    updateIsLogin (state, isLogin) {
      state.isLogin = isLogin;
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    }
  },
  actions: {
    checkToken() {
      return requestHttp({
        urlName:'account_heartBeat',
      }).then(res => {
        if (res.code !== 2000) {
          // localStorage.removeItem('userInfo');
          localStorage.removeItem('token');
          /* sessionStorage.removeItem("userInfo");
          sessionStorage.removeItem('ba-user-token'); */
          // commit('updateIsLogin', false)
        } else {
          // commit('updateIsLogin', true)
        }
        return res
      })
    },
	//检查 ba-user-token 
	checkUserToken({dispatch, commit}){
		return requestHttp({
		  urlName:'account_isLogin',
		}).then(res => {
		  if (!res.data) {
			localStorage.removeItem('ba-user-token');
		    commit('updateIsLogin', false)
			dispatch('checkToken');
		  }
		  return res
		})
	},
    login: debounce(({dispatch, commit}) => {
      return dispatch('checkToken').then((resp) => {
        if (resp.code == 2000) {
          return requestHttp({
            urlName:'user_authWithToken',
            data:{
              token: localStorage.getItem('token')
            }
          }).then(res=>{
            // user.value=res.data.userinfo;
            commit('updateUserInfo', res.data.userinfo)
            localStorage.setItem("userInfo",JSON.stringify(res.data.userinfo));
            localStorage.setItem('ba-user-token',res.data.userinfo.token);
            commit('updateIsLogin', true)
            return res
          })
        }
      })
    }, 1000)
  }
})

export default store;
