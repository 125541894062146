<template>
	<div class="containers-nav-menu">
		<div class="menu-item" @click="jump('/my',0)">
			<img src="@/assets/icon/menu-1-icon.svg" />我的收藏
		</div>
		<div class="menu-item" @click="jump('/my',2)">
			<img src="@/assets/icon/menu-2-icon.svg" />我的订单
		</div>
		<div class="menu-item" @click="jump('/my',1)">
			<img src="@/assets/icon/menu-3-icon.svg" />已购模型
		</div>
		<div class="menu-out" @click="logout">
			退出登录
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ty-nav-menu'
	}
</script>
<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
const jump = (url,q)=>{
	router.push({ path: url,  query: {index:q} });
}	
const logout = () => {
	localStorage.removeItem('token')
	localStorage.removeItem('userInfo')
	localStorage.removeItem('userInfo')
	localStorage.removeItem('ba-user-token')
	location.reload()
}
</script>

<style lang="less" scoped>
	@import url("../../index.less");
	.containers-nav-menu{
		width: 12rem;
		height: 16.875rem;
		background: #FFFFFF;
		box-shadow: 0px 0px 0.875rem 0px rgba(0,0,0,0.11);
		border-radius: 1.125rem;
		padding: 0.5rem;
		box-sizing: border-box;
		user-select: none;
		.menu-item{
			height: 3.75rem;
			width: 100%;
			border-radius: 1.125rem;
			font-size: 1.125rem;
			font-family: @font-family;
			font-weight: 500;
			color: #333333;
			display: flex;
			justify-content: center;
			align-items: center;
			transition: all @animation-time;
			cursor: pointer;
			img{
				width: 1.25rem;
				height: 1.25rem;
				margin-right: 0.25rem;
			}
		}
		.menu-item:hover{
			background: #EFEFEF;
		}
		.menu-out{
			padding: 1.375rem 0 1.125rem 0;
			margin: 0px 0.5rem;
			box-sizing: border-box;
			font-size: 1.125rem;
			font-family: @font-family;
			font-weight: 500;
			color: #333333;
			text-align: center;
			transition: all @animation-time;
			border-top: 1px solid #EFEFEF;
			cursor: pointer;
		}
		.menu-out:hover{
			border-radius: 1.125rem;
			background: #EFEFEF;
		}
	}
</style>