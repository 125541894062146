<template>
	<div class="containers">
		<ty-nav :fade="false" :is-help="true"></ty-nav>
		<div class="layout">
      <help-left-section active="更新日志" />
      <div class="right-section">
        <v-md-preview :text="mdText"></v-md-preview>
      </div>
    </div>
	</div>
</template>
<script setup>
  import {ref} from "vue";
  const mdText = ref('');
  const readFile = (filePath) => {
		var xhr= new XMLHttpRequest(),
		method = "GET",
		url = filePath;

		xhr.open(method, url, true);
		xhr.onreadystatechange = function () {
			if(xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
				mdText.value = xhr.responseText;
			}
		}
		xhr.send();
	};
	readFile('./update.md');
</script>
<style lang="less" scoped>
	@import url("../index.less");
  @import url("../markdown.less");

	.containers {
		.layout {
      display: flex;
			box-sizing: border-box;
			min-height: 100vh;
			padding-bottom: 11.375rem;
      margin-top: 4.625rem;
      .right-section {
        flex: 1;
      }
		}
	}
</style>
