<template>
	<div class="containers-footer">
		<div>
			<div class="footer-contact">
				<div class="list">
					<div class="list-name">模型资产</div>
					<div class="list-item tap" v-for="(item,index) in options" :key="item"  @click="tapModel(index)">{{item}}</div>
					
				</div>
				<div class="list">
					<div class="list-name">应用场景</div>
					<a class="list-item" href="https://taiyi.cn/" target="_blank">太一灵境</a>
					<a class="list-item" href="https://taiyi.cn/meta-tang" target="_blank">大唐灵境</a>
					<a class="list-item" href="https://taiyi.cn/cloud-club" target="_blank">太一云境</a>
					<a class="list-item" href="https://taiyi.cn/star-one" target="_blank">StarOne超级岛</a>
				</div>
				<div class="list">
					<div class="list-name">帮助中心</div>
					<router-link class="list-item" to="/qa">常见问题</router-link>
					<router-link class="list-item" to="/updateLog">更新日志</router-link>
				</div>
				<div class="list">
					<div class="list-name">联系我们</div>
					<div class="list-item">商务邮箱：bd@taiyi.cn</div>
					<div class="list-item">商务电话：18612849818</div>
					<div class="list-item">联系地址：北京市朝阳区高碑店南岸一号A1座</div>
				</div>
				<div class="list">
					<div class="list-name">客服支持</div>
					<img class="qrcode" src="https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20230721/nKcQM1XqKNUcvGsV.jpg" />
				</div>
			</div>
			<div class="footer-company">
				版权所有 taiyi.cn ©2023  太一云境技术有限公司　　　　京ICP备2021008220号-8
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ty-footer'
	}
</script>
<script setup>
	const props = defineProps({
		options:{
			type:Array,
			default:[]
		}
	});
	const emit = defineEmits(['change']);
	
	// 切换选中
	const tapModel=(e)=>{
		emit('change', e)
	}
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.containers-footer{
		// height: 9.625rem;
		.tap{
			cursor: pointer;
			user-select: none;
		}
		background: #F0F1F3;
		padding: 4rem 0;
		box-sizing: border-box;
		.footer-contact{
			display: flex;
			padding: 0 24.9rem;
			justify-content: space-between;
			span{
				font-size: 1rem;
				font-family: @font-family;
				font-weight: 400;
				color: #999999;
				user-select: none;
				cursor: pointer;
			}
			span:first-child{
				font-weight: 600;
				color: #333333;
			}
			.list {
				.list-name {
					font-size: 1rem;
					font-family: @font-family;
					font-weight: 500;
					color: #333;
					line-height: 2.25rem;
				}
				.list-item {
					display: block;
					line-height: 1.75rem;
					font-size: 0.875rem;
					color: #999;
					text-decoration: none;
				}
				.qrcode {
					width: 6.25rem;
					height: 6.25rem;
				}
			}
		}
		.footer-company{
			margin-top: 2.5rem;
			font-size: 0.9375rem;
			font-family: @font-family;
			font-weight: 400;
			color: #999999;
			text-align: center;
		}
			
		.footer-icp{
			font-size: 0.9375rem;
			font-family: @font-family;
			font-weight: 400;
			color: #999999;
			text-align: center;
			margin-top: 0.125rem;
		}
	}
</style>