<template>
	<div>
		<div class="containers-checkbox">
			<input type="checkbox" class="ty-checkbox" :checked="modelValue" :id="for" @change="onChange(modelValue)" />
			<label :for="for" :class="modelValue?'select':''">
				<slot></slot>
			</label>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ty-checkbox'
	}
</script>
<script setup>
	import {
		toRefs
	} from 'vue';
	const props = defineProps(['for', 'modelValue']);
	const emit = defineEmits(['update:modelValue']);
	const {
		modelValue
	} = toRefs(props);
	const onChange = (res) => {
		emit('update:modelValue', !res)
	}
</script>

<style lang="less" scoped>
	@import url("../../index.less");

	.containers-checkbox {
		display: flex;
		align-items: center;
		
		input[type="checkbox"] {
			position: relative;
			width: 1rem;
			height: 1rem;
			cursor: pointer;
		}

		input[type="checkbox"]::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			background-color: #fff;
			width: 1rem;
			height: 1rem;
			background-image: url(@/assets/icon/checked-d-icon.svg);
			background-repeat: no-repeat;
			background-size: 1rem 1rem;
			transition: @animation-time all;
		}

		input[type="checkbox"]:checked::before {
			transition: @animation-time all;
			border: none;
			background-image: url(@/assets/icon/checked-icon.svg);
			width: 1rem;
			height: 1rem;
			background-repeat: no-repeat;
			background-size: 1rem 1rem;
		}

		label {
			user-select: none;
			font-size: 1.125rem;
			font-family: @font-family;
			font-weight: 400;
			color: #333333;
			margin-left: 0.25rem;
			cursor: pointer;
			transition: @animation-time all;
		}

		.select {
			transition: @animation-time all;
			color: @primary-color;
		}
	}
</style>
