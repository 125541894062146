<template>
	<div class="containers-input">
		<input type="text" :placeholder="placeholder" :value="modelValue" @input="onInput"/>
	</div>
</template>

<script>
	export default {
		name: 'ty-input'
	}
</script>
<script setup>

	const props = defineProps(['placeholder','modelValue'])
	const emit = defineEmits(['update:modelValue']);
	const onInput = (e) => {
		const value = e.target.value;
		emit('update:modelValue', value)
	}
</script>

<style lang="less" scoped>
	@import url("../../index.less");
	.containers-input{
		height: 3.125rem;
		width: 100%;
		background: #F2F2F4;
		border-radius: 0.75rem;
		border: 1px solid #E8E8E8;
		position: relative;
		input{
			width: inherit;
			height: inherit;
			border: none;
			outline: none;
			border-radius: inherit;
			background-color: inherit;
			font-family: @font-family;
			font-size: 1.125rem;
			font-weight: 400;
			color: #333333;
			padding: 0px 1.125rem;
			box-sizing: border-box;
		}
	}
</style>