import {
	createRouter,
	createWebHashHistory
} from 'vue-router';

// // 自动注入路由
const modules = require.context('../views', true, /.vue/);
const autoRoutes = modules.keys().filter(item => item.indexOf("components") == -1).map((item) => {
	const viewName = item.slice(2);
	const path = item.slice(1).replace('.vue', '').replace('index', '');
	const viewModule = () => import(`../views/${viewName}`);
	return {
		path,
		component: viewModule,
	};
});
const routes = autoRoutes;

const router = createRouter({
	history: createWebHashHistory(),
	routes: [
		{
			path: '/',
			name: 'Home',
			redirect: '/home'
		},
		{
			path: '/detail/:modelId',
			name: 'detail',
			component: import('@/views/detail')
		},
		{
			path: '/open/:modelId',
			name: 'open',
			component: import('@/views/open')
		},
		...routes
	],
});


 export default router;

