<template>
	<div class="containers-media-video">
		<video :src="videoSrc" controls autoplay></video>
	</div>
</template>

<script>
	export default {
		name: 'detail-media-video'
	}
</script>
<script setup>
	defineProps(['videoSrc']);
</script>
<style lang="less" scoped>
	.containers-media-video{
		width: 100%;
		height: 100%;
		overflow: hidden;
		background-color: #000000;
		video{
			width:inherit;
			height:inherit;
		}
	}
</style>