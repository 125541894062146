<template>
	<div class="containers-tabs">
		<div class="tabs-item " :class="index==modelValue?'tabs-item-action':''" v-for="(item,index) in options"
		:key="index"
		@click="toggleOptions(index)">
			{{item}}
		</div>
	</div>
</template>
<script>
	export default {
		name: 'tabs'
	}
</script>
<script setup>
import { ref } from 'vue';
defineProps(['options','modelValue']);
const emit = defineEmits(['update:modelValue'])
const toggleOptions=(index)=>{
	emit('update:modelValue', index)
}
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.containers-tabs {
		border-radius: 0.875rem;
		border: 1px solid #E8E8E8;
		padding: 0.375rem;
		box-sizing: border-box;
		display: inline-flex;
		user-select: none;
		min-width: 19.875rem;
		transition: @animation-time all;
		.tabs-item {
			height: 3.125rem;
			color: #727782;
			border-radius: 0.875rem;
			font-size: 1.25rem;
			font-family: @font-family;
			font-weight: 500;
			padding: 0.6875rem 1.5625rem;
			box-sizing: border-box;
			cursor: pointer;
			transition: @animation-time all;
			
		}
		.tabs-item-action {
			background: #EFEFEF;
			color: #333333;
			transition: @animation-time all;
			font-weight: 600;
		}
	}
</style>
