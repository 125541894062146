<template>
	<div class="containers-ad">
		<div>
			<div class="logo"><img
					src="https://www.taiyiyunjia.com/upload/image/20221010/b28bccac9976a6a7308eb6049985972f.png" />
				太一云家</div>
			<div class="info">
				实物商品同步热销
			</div>
		</div>
		<div class="jump">
			<button class="button-jump" @click="jump(node.url)">立即前往</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'introduce-ad'
	}
</script>
<script setup>
	defineProps({
		node:{
			type:Object,
			required: true
		}
	});
	const jump = (url) =>{
		window.location.href=url;
	}
	
</script>
<style lang="less" scoped>
	@import url("../../index.less");
	.containers-ad {
		height: 6.25rem;
		width: 100%;
		background: #FFFFFF;
		border-radius: 1.125rem;
		border: 1px solid #EFEFEF;
		padding: 1.25rem 2rem;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.logo {
			font-size: 0.875rem;
			font-family: @font-family;
			font-weight: 400;
			color: #333333;
			display: flex;
			align-items: center;
			height: 1.5rem;
			img {
				height: 1.5rem;
				width: auto;
				margin-right: 0.125rem;
			}
		}
		.info{
			font-size: 1.25rem;
			font-family: @font-family;
			font-weight: 500;
			color: #333333;
			margin-top: 0.5rem;
		}
		.button-jump{
			width: 6.25rem;
			height: 2.25rem;
			border-radius: 0.5rem;
			background-color: #FFFFFF;
			font-size: 1rem;
			font-family: @font-family;
			font-weight: 400;
			color: #00CC5A;
			border: 1px solid @primary-color;
			cursor: pointer;
		}
	}
</style>
