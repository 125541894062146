<template>
	<div class="containers-detail-button">
		<!-- 是否 禁用购买功能 -->
		<template v-if="disabled">
			<template v-if="isLogin">
				<!-- if已登录 -->
				<button class="button-download" disabled>
					敬请期待
				</button>
				<!-- 登录成功显示收藏按钮 -->
				<button class="button-collect" @click="collect('禁用购买功能>切换是否收藏',isCollect)">
					<div v-if="isCollect">
						<div><span class="iconfont icon-yishoucang"></span></div>
						<div>已收藏</div>
					</div>
					<div v-else>
						<div><span class="iconfont icon-shoucang"></span></div>
						<div>收藏</div>
					</div>
				</button>
				<!-- end 登录成功显示收藏按钮 -->
			</template>	
			<template v-else>
				<button class="button-download button-max" @click="login('没有登录>禁用购买功能>去登录',7)">
					去登录
				</button>
			</template>
		</template>
		<template v-else>
			<!-- else 没有禁用购买 -->
			<template v-if="isLogin">
				<!-- if已登录 -->
				<template v-if="isFlie">
					<!-- if有下载文件 -->
					<template v-if="isFree">
						<!-- if 是免费计划 -->
						<button class="button-download" @click="download('已登录>有下载文件>是免费计划>立即下载',0)">立即下载</button>
					</template>
					<template v-else>
						<!-- else 不是免费计划 -->
						<template v-if="isPay">
							<!--  if 已经购买 -->
							<button class="button-download" @click="download('已登录>有下载文件>不是免费计划>已经购买>立即下载',1)">立即下载</button>
						</template>
						<template v-else>
							<!-- else 没有购买 -->
							<button class="button-download" @click="payment('已登录>有下载文件>不是免费计划>立即购买',2)">立即购买</button>
						</template>
					</template>
				</template>
				<template v-else>
					<!-- else 没有下载文件 -->
					<button class="button-download" disabled>
						敬请期待
					</button>
				</template>
				<!-- 登录成功显示收藏按钮 -->
				<button class="button-collect" @click="collect('没有禁用购买功能>切换是否收藏',isCollect)">
					<div v-if="isCollect">
						<div><span class="iconfont icon-yishoucang"></span></div>
						<div>已收藏</div>
					</div>
					<div v-else>
						<div><span class="iconfont icon-shoucang"></span></div>
						<div>收藏</div>
					</div>
				</button>
				<!-- end 登录成功显示收藏按钮 -->
			</template>
			<template v-else>
				<!-- else 没有登录 -->
				<template v-if="isFlie">	
					<!-- if有下载文件 -->
					<template v-if="isFree">
						<!-- if 是免费计划 -->
						<button class="button-download button-max"  @click="login('没有登录>是免费计划>登录后下载',4)">登录后下载</button>
					</template>
					<template v-else>
						<!-- else 不是免费计划 -->
						<button class="button-download button-max"  @click="login('没有登录>不是免费计划>登录后购买',5)">登录后购买</button>
					</template>
				</template>
				<template v-else>
					<!-- else 没有下载文件 -->
					<button  class="button-download button-max"  @click="login('没有登录>没有下载文件>去登录',6)">去登录</button>
				</template>
			</template>
		</template>
	</div>
</template>

<script>
	export default {
		name: 'detail-button'
	}
</script>
<script setup>
	defineProps({
		//是否禁止售卖
		disabled:{
			type:Boolean,
			default:false
		},
		//是否已经登录
		isLogin:{
			type:Boolean,
			default:false
		},
		//是否已收藏
		isCollect:{
			type:Boolean,
			default:false
		},
		//是否有下载文件
		isFlie:{
			type:Boolean,
			default:false
		},
		//是否是免费计划
		isFree:{
			type:Boolean,
			default:false
		},
		//是否已购买
		isPay:{
			type:Boolean,
			default:false
		}
	})
	const emit = defineEmits(['changeCollect','tapDownload','tapPayment' ,'tapLogin']);
	
	/**
	 * 下载
	 */
	const download = (info,type) =>{
		emit('tapDownload',{details:info,buttonType:type});
	}
	/**
	 * 支付
	 */
	const payment = (info,type)=>{
		emit('tapPayment',{details:info,buttonType:type});
	}
	
	/**
	 * 切换收藏
	 */
	const collect = (info,isColl)=>{
		emit('changeCollect',{details:info,buttonType:3,value:!isColl});
	}
	
	/**
	 * 登录
	 */
	const login = (info,type)=>{
		emit('tapLogin',{details:info,buttonType:type});
	}
	
</script>

<style lang="less" scoped>
	@import url("../../index.less");
	.containers-detail-button{
		display: flex;
		.button-download {
			border: none;
			width: 16.25rem;
			height: 3.75rem;
			background: @primary-color;
			border-radius: 0.875rem;
			font-size: 1.5rem;
			font-family: @font-family;
			font-weight: 400;
			color: #FFFFFF;
			cursor: pointer;
			&:disabled {
				background: #DADADA;
			}
		}
		.button-max{
			width: 100%;
		}
		.button-download:disabled{
			background: #DADADA;
			font-weight: 400;
			color: #FFFFFF;
			cursor: no-drop;
		}
		.button-disabled{
			background: #DADADA;
			font-weight: 400;
			color: #FFFFFF;
			cursor: no-drop;
			font-size: 1.5rem;
			font-family: @font-family;
			border: none;
			font-weight: 400;
			color: #FFFFFF;
			border-radius: 0.875rem;
			width: 16.25rem;
		}
		.button-collect {
			border: none;
			cursor: pointer;
			margin-left: 1.125rem;
			width: 3.75rem;
			border-radius: 0.75rem;
			border: 1px solid #F5F5F5;
			background-color: #FFFFFF;
			padding: 0.25rem 0;
			font-size: 0.875rem;
			font-family: @font-family;
			box-sizing: border-box;
			font-weight: 400;
			color: #999999;
			.iconfont {
				font-size: 2rem;
			}
			.icon-yishoucang {
				color: #FFB50F;
			}
		}
	}
</style>