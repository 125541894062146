<template>
	<div class="containers-media-sequence" :id="sequenceID" >
		<img v-if="load" src="@/components/taiyi-fbx-previewer/assets/loading.gif" >
		<div :class="load==false?'showMeta':''" :id="metaID"></div>
	</div>
</template>

<script>
	export default {
		name: 'detail-media-sequence'
	}
</script>
<script setup>
	import $ from "./jqueryVender.js";
	import * as SpriteSpin from 'spritespin';
	import {
		onMounted,
		ref,
		getCurrentInstance,
		nextTick
	} from 'vue';
	const _this = getCurrentInstance().proxy;
	const cdn = ref(_this.$site.cdn_url); //cdn
	const load = ref(true);//加载中
	let props = defineProps({
		config: {
			type: Object,
			default: null
		}
	})
	let urlAlphabet =
		'useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict';
	window.onlyidHistory = []; //唯一id 历史记录
	
	/**
	 * 生成唯一 id
	 */
	let onlyid=(size = 21)=>{
		let id = ''
		let i = size
		while (i--) {
			id += urlAlphabet[(Math.random() * 64) | 0]
		}
		let index = onlyidHistory.findIndex(res => res == id);
		if (index == -1) {
			onlyidHistory.push('tai' + id);
			return 'tai' + id
		} else {
			onlyid();
		}
	};
	const metaID= ref(onlyid());//唯一id
	const sequenceID = ref(onlyid());//唯一id
	onMounted(() => {
		let imglist = [];
		let count = Number(props.config.count);//图片数量
		for (var i = 0; i < count; i++) {
			let name ='00';
			if (i<10) {
				name+='00'+i
			} else if(i<100){
				name+='0'+i
			}else {
				name+=i
			}
			imglist.push(`${cdn.value}${props.config.file[0]}/${name}.${props.config.suffix}`)
		}
		$(`#${metaID.value}`).spritespin({
			frames:count,
			frameTime:count,
			source: imglist,
			sense: -1,
			width: $(`#${sequenceID.value}`).width(),
			height: $(`#${sequenceID.value}`).height(),
			sizeMode: 'fit',
			retainAnimate:true,
			onLoad : function() {
				load.value=false;//加载完成
			}
		});
		
	})
</script>
<style lang="less" scoped>
	.containers-media-sequence{
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: radial-gradient(center, circle cover, rgb(255, 255, 255) 0%, rgb(224, 227, 234) 100%);
		background: -o-radial-gradient(center, circle cover, rgb(255, 255, 255) 0%, rgb(224, 227, 234) 100%);
		background: -ms-radial-gradient(center, circle cover, rgb(255, 255, 255) 0%, rgb(224, 227, 234) 100%);
		background: -moz-radial-gradient(center, circle cover, rgb(255, 255, 255) 0%, rgb(224, 227, 234) 100%);
		background: -webkit-radial-gradient(center, circle cover, rgb(255, 255, 255) 0%, rgb(224, 227, 234) 100%);
		img{
			position: absolute;
		}
		#media{
			cursor: grab;
			opacity: 0;
		}
		.showMeta{
			opacity: 1 !important;
		}
	}
</style>