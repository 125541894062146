<template>
  <div class="left-section">
    <div :class="{
        active: active === '常见问题'
      }"
      @click="toQa"
    >常见问题</div>
    <div :class="{
        active: active === '更新日志'
      }"
      @click="toUpdateLog"
    >更新日志</div>
  </div>
</template>

<script>
  import { useRouter } from "vue-router"; 
	export default {
		name: 'help-left-section',
    props: {
      active: {
        type: String,
        required: true,
      }
    },
    setup() {
      const router = useRouter();
      const toUpdateLog = () => {
        router.push('/updateLog');
      }
      const toQa = () => {
        router.push('/qa');
      }
      return {
        toUpdateLog,
        toQa
      }
    }
	}
</script>

 <style scoped lang="less">
 .left-section {
  width: 19.5rem;
  padding-top: 2.0625rem;
  flex-shrink: 0;
  text-align: center;
  color: #999;
  border-right: 1px solid #EFEFEF;
  div {
    line-height: 3.125rem;
    cursor: pointer;
    &:hover {
      color: #333;
    }
    &.active {
      position: relative;
      color: #333;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: .375rem;
        bottom: 0;
        width: .25rem;
        height: 2.375rem;
        background: #333;
      }
    }
  }
}
 </style>