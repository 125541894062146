import request  from '@/request/serve';

/**
 * 递归获取api 目录 下面全部接口
 * @param {string} urlName 接口名称
 */
function getParameter(urlName) {
	const files = require.context('./api', true, /\.js$/);
	let pages = {};
	files.keys().forEach(key => {
		pages=Object.assign(pages,files(key).default);
	})
	return pages[urlName];
}


/**
 * 全局请求
 * @param {string}  urlName 请求名称
 * @param {object}  data 请求参数
 * @param {string}  extra 需要在地址中追加的额外参数
 * 
 */
export function requestHttp({urlName,data=null,extra=''}) {
	let parm = getParameter(urlName);
	if(parm.method=='GET'){
		return request({
			url:parm.url+extra,
			method:parm.method,
			params:data
		})
	}else{
		return request({
			url:parm.url+extra,
			method:parm.method,
			data:data
		})
	}
}