<template>
	<div class="containers">
		<ty-nav v-model="navKey" @enter="enterSearch()" :options="tabOptions" @change="changeTab"></ty-nav>
		<!-- 轮播图 -->
		<rotating-banner></rotating-banner>
		<!-- 列表 -->
		<div ref="listBody" class="list-body">
			<div class="list-tools" ref="sticky" :class="isSticky?'sticky-tools':''">
				<div class="tools-left">
					<tabs :options="tabOptions" v-model="tabIndex" class="tabs"></tabs>
					<dropdown :lable="modelInfo.lable" ref="childModel" @change="changeModel" 
					v-model="modelInfo.is" icon="classification-icon.svg">
						<model-array :array="modelInfo.array" @change="toggleModel"  :pid="tabPID"></model-array>
					</dropdown>
				</div>
				<div class="tools-right">
					<dropdown :lable="popularInfo.lable" ref="childPopular"  @change="changePopular" v-model="popularInfo.is" icon="sort-icon.svg" class="popular">
						<popular-array :array="popularInfo.array" @change="togglePopular"></popular-array>
					</dropdown>
					<dropdown :lable="'共'+conut+'个'" v-model="filterInfo.is" ref="childFilter"  @change="changeFilter"  icon="filter-icon.svg">
						<Filter-Panel ref="filterPanel" :pid="tabPID"
						@save="filter"
						@reset="filter"
						/>
					</dropdown>
				</div>
			</div>
			
			<div class="list-model">
				<omit-model v-for="item in listData" class="omit" 
				:collect="item.is_favorited==1" 
				:free="item.free_switch==1"
				:node="item"
				:isCollect="true"
				:key="item.id"
				:mediaType="item.images_videos.length>0?'video':'img'"
				@change="changeOmit"
				></omit-model>
			</div>
			<button class="button-more" v-show="more"
			@click="tapMore()"
			>查看更多</button>
			
			<null-data v-show="tabPID!=null && conut==0 && more==false"></null-data>
		</div>
		<ty-footer :options="tabOptions" @change="changeTab"></ty-footer>
	</div>
</template>
<script setup>
	import config from './config.json';
	import {
		getCurrentInstance,
		onMounted,
		watch,
		ref,
		computed,
		onUnmounted
	} from 'vue';
	import { useStore } from 'vuex';
	import {
		useRoute,
		useRouter
	} from 'vue-router';
	// 获取当前路由
	const route = useRoute();
	const router = useRouter();
	const store = useStore();
	const sticky = ref();//黏贴
	const isSticky=ref(false);//是否已经黏贴
	const tabOptions=ref([]);//一级分类列表
	const tabAll = ref([]);//全部分类数据
	const tabPID = ref(null);//pid
	const tabIndex = ref(0); //tabs 选中索引
	const listData = ref([]);// 列表数据源
	const pageIndex = ref(1);//当前页码
	const more = ref(false);//是否显示加载更多
	const navKey= ref('');//导航条中的key
	const conut = ref(0);//总数
	const childModel = ref();
	const childPopular = ref();
	const childFilter= ref();
	const filterPanel= ref(); // 筛选组件
	const listBody= ref(); // 筛选组件
	
	const form = ref({
		keyword:'',
		tags:'',
		categories:'',
		free:0
	});//表单搜索
	const isLogin = computed(() => store.state.isLogin)
	/**
	 * 回车搜索
	 */
	const enterSearch=()=>{
		listData.value=[];
		more.value=false;
		pageIndex.value=1;
		form.value.keyword=navKey.value;
		// console.log(filterPanel)
		// 清空二级分类
		modelInfo.value.lable='全部模型';
		modelInfo.value.index=0;
		form.value.categories = '';
		form.value.tags = '';
		form.value.free = false;
		// 同步至筛选组件的分类
		filterPanel.value.changeCate('');
		// 同步至筛选组件的搜索
		filterPanel.value.changeKeyword(navKey.value);
		// 清空筛选组件的标签
		filterPanel.value.clearTag();
		// 重置筛选组件的免费计划
		filterPanel.value.resetSwitch();
		find_List();//查询
		listBody.value.scrollIntoView({behavior: "smooth"})
	};
	
	/**
	 * 收藏发生变化时候发生
	 */
	const changeOmit=(e)=>{
		let list = listData.value.map((res)=>{
			if(res.id==e.id){
				res.is_favorited=e.select?1:0;
			}
			return res;
		});
	};
	/**
	 * 监听tab 变化
	 */
	const changeTab=(e)=>{
		tabIndex.value=e;
		listBody.value.scrollIntoView({behavior: "smooth"})
	}
	
	/**
	 * 保存筛选
	 */
	const filter=(e)=>{
		listData.value=[];
		more.value=false;
		pageIndex.value=1;
		navKey.value='';
		form.value=e;
		modelInfo.value.lable='全部模型';
		modelInfo.value.index=0;
		
		find_List();//查询
	}
	
	/**
	 * 加载更多
	 */
	const tapMore=()=>{
		pageIndex.value+=1;
		find_List();//查询
	}
	
	
	const _this = getCurrentInstance().proxy;
	//模型data
	const modelInfo=ref({
		is:false,
		array:[{
            "id":null,
            "pid":null,
            "name": "全部模型",
            "updatetime": null,
            "createtime": null
        }],
		index:0,
		lable:'全部模型'
	})
	//热门data
	const popularInfo=ref({
		is:false,
		array:['由新到旧','由旧到新','按热门值'],
		lable:'按热门值',
		index:2
	})
	
	//筛选data
	const filterInfo=ref({
		is:false
	})

	//切换模型function
	const toggleModel=({lable,index})=>{
		modelInfo.value.lable=lable;
		modelInfo.value.index=index;
		childModel.value.toggleSwitch(false);
		//查询
		listData.value=[];
		more.value=false;
		pageIndex.value=1;
		let id = modelInfo.value.array[modelInfo.value.index].id;
		form.value.categories=id!=null?id:'';
		form.value.keyword = '';
		form.value.tags = '';
		form.value.free = false;
		navKey.value = '';
		// 清空筛选组件的keyword
		filterPanel.value.changeKeyword(navKey.value);
		// 同步至筛选组件的分类
		filterPanel.value.changeCate(modelInfo.value.array[modelInfo.value.index].id);
		// 清空筛选组件的标签
		filterPanel.value.clearTag();
		// 重置筛选组件的免费计划
		filterPanel.value.resetSwitch();
		find_List();
		//-end查询
	}
	//切换热门function
	const togglePopular=({lable,index})=>{
		popularInfo.value.lable=lable;
		popularInfo.value.index=index;
		childPopular.value.toggleSwitch(false);
		
		//查询
		listData.value=[];
		more.value=false;
		pageIndex.value=1;
		find_List();
		//-end查询
	}
	
	
	//模型状态发生变化时触发
	const changeModel=(res)=>{
		if(res) childPopular.value.toggleSwitch(false);childFilter.value.toggleSwitch(false);
	}
	//热门状态发生变化时触发
	const changePopular=(res)=>{
		if(res) childModel.value.toggleSwitch(false);childFilter.value.toggleSwitch(false);
	}
	//筛选状态发生变化时触发
	const changeFilter=(res)=>{
		if(res) childModel.value.toggleSwitch(false);childPopular.value.toggleSwitch(false);
	}
	
	/**
	 * 获取（搜索过滤）首页模型数据列表
	 */
	const find_List = () =>{
		_this.$request({
			urlName:'index_moxing',
			data:{
				page:pageIndex.value,//页码
				sort:popularInfo.value.index+1,//排序
				keyword:form.value.keyword,
				tags:form.value.tags,
				categories:form.value.categories,
				free:form.value.free,
				categories_root:tabPID.value
			}
		}).then(res=>{
			listData.value = listData.value.concat(res.data);
			if(res.data.length==20){
				more.value=true;
			}else{
				more.value=false;
			}
			conut.value= res.msg;
		})
		
	}
	
	watch(
	  () => tabIndex.value,
	  (newVal, oldVal) => {
		//查询
		listData.value=[];
		more.value=false;
		pageIndex.value=1;
		modelInfo.value.lable='全部模型';
		modelInfo.value.index=0;
		form.value.categories='';
		form.value.keyword='';
		form.value.tags='';
		form.value.free=false;
		navKey.value = '';
		filterPanel.value.changeKeyword('');
		filterPanel.value.changeCate('');
		filterPanel.value.clearTag();
		filterPanel.value.resetSwitch();
		let id = tabAll.value.filter(res=>res.name==tabOptions.value[newVal])[0].id;//查询父id
		tabPID.value = id;
		find_List();
		//-end查询
	  }
	);
	
	
	//查询分类  
	const find_Type = () =>{
		return new Promise((resolve,reject)=>{
			_this.$request({
				urlName:'index_categories'
			}).then(res=>{
				
				let tabs1 = res.data.filter(rs=>rs.pid==0).map((re)=>{
					return re.name;
				});
				
				//处理隐藏的tabs 
				config.showTabs.forEach(res=>{
					if(!route.query[res.RouteKey]){
						//过滤
						res.tabs.forEach(key=>{
							tabs1=tabs1.filter(tb=>tb!=key);
						})
					}
				})
				tabOptions.value = tabs1;
				tabAll.value=res.data;
				let id = res.data.filter(res=>res.name==tabOptions.value[0])[0].id;//查询父id
				tabPID.value = id;
				modelInfo.value.array= modelInfo.value.array.concat(
					res.data
				);
				
				resolve();
			})
		})
	
	}
	
	/**
	 * 监听滚动
	 */
	const onScroll = () =>{
		let _thisTop = sticky.value.getBoundingClientRect().top;
		let _xy = parseFloat(document.documentElement.style.fontSize)*5;
		if(_thisTop<=_xy){
			isSticky.value=true;
		}else{
			isSticky.value=false;
		}
	}
	onMounted(async ()=>{
		//验证 token
		await store.dispatch('checkUserToken');
		if (!isLogin.value) await store.dispatch('login');
		find_Type().then(res=>{
			find_List();//查询列表
		});//查询分类
		onScroll();
		window.addEventListener('scroll',onScroll)
	})
	onUnmounted(() => {
		window.removeEventListener("scroll", onScroll)
	})
</script>

<style lang="less" scoped>
	@import url("../index.less");
	.containers {
		.list-body {
			padding-top: 3.625rem;
			box-sizing: border-box;
			min-height: 100vh;
			padding-bottom: 11.375rem;
			.list-tools {
				display: flex;
				padding: 1.125rem 6.25rem;
				box-sizing: border-box;
				justify-content: space-between;
				position: sticky;
				top:5rem;
				z-index: 80;
				background-color: #fff;
				.tools-left {
					display: flex;
					.tabs {
						margin-right: 2.25rem;
					}
					
				}
				.tools-right {
					display: flex;
					.popular {
						margin-right: 2.25rem;
					}
				}
			}
			.sticky-tools{	
				box-shadow: 0px 2px 0.6875rem 0px #EFEFEF;
			}
			.list-model{
				display: grid;
				padding: 0px 6.25rem;
				box-sizing: border-box;
				grid-template-columns: repeat(auto-fill, 367px);
				justify-content: space-between;
				.omit{
					margin-top: 2.25rem;
				}
			}
			.button-more{
				width: 9.375rem;
				height: 3.125rem;
				border-radius: 2.125rem;
				border: 1px solid #E8E8E8;
				margin: 0px auto;
				display: block;
				margin-top: 4rem;
				font-size: 1.125rem;
				font-family: @font-family;
				font-weight: 500;
				user-select: none;
				color: #666666;
				background-color: #fff;
				cursor: pointer;
			}
		}


	}
</style>
