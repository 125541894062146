<template>
	<div class="containers-open" v-if="detailDB!=null">
		<template v-if="detailDB.preview_fbx[0].type && detailDB.preview_fbx[0].type=='sequence'">
			<detail-media-sequence   
			:config="detailDB.preview_fbx[0].sequence"
			></detail-media-sequence>
		</template>
		<template v-else>
			<detail-media-model
			:url="detailDB.preview_fbx[0].fbx"
			:config="detailDB.preview_fbx[0].preview"
			:noBackground="true"
			></detail-media-model>
		</template>
		
	</div>
</template>

<script setup>
	import {
		ref,
		getCurrentInstance,
		onMounted,
	} from 'vue';
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	// 获取当前路由
	const route = useRoute();
	const router = useRouter();
	const _this = getCurrentInstance().proxy;
	
	const detailDB = ref(null); // 详情数据
	/**
	 * 查询详情
	 */
	const find_Detail = (id) => {
		_this.$request({
			urlName: 'index_getMoxing',
			data: {
				id: id
			}
		}).then(res => {
			detailDB.value = res.data;
		})
	}
	onMounted(() => {
		let id = route.query.id ? route.query.id: route.params.modelId;
		find_Detail(id);
	})
</script>

<style lang="less" scoped>
	.containers-open{
		width: 100vw;
		height: 100vh;
		overflow: hidden;
	}
</style>