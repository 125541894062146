/**
 * 自动注册组件
 */
export function createComponents(app) {
	const modules = require.context('../views', true, /index\.vue$/);
	let nodeComponents = modules.keys().filter(item => item.indexOf("components") > 0);
	//子组件列表
	nodeComponents.forEach((item) => {
		const fileItem = modules(item).default;
		if (fileItem.name) {
			app.component(fileItem.name, fileItem)
		}
	})
}

