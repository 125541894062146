<template>
	<div class="containers-media-model" :class="noBackground==true ? 'noBackground':''">
		<fbx-preview class="fbx"  :encoded="true" :config="config" :url="url" ref="fbxRe"></fbx-preview>
	</div>
</template>

<script>
	export default {
		name: 'detail-media-model'
	}
</script>
<script setup>
	defineProps(['config','url','noBackground']);
	import {ref,defineExpose} from 'vue';
	const fbxRe = ref();//refs
	
	// 重置模型
	const resetCamera = () => {
	  fbxRe.value.resetCamera();
	}
	// 刷新模型尺寸以适应父组件
	const toggleFullScreen = () => {
	  fbxRe.value.toggleFullScreen();
	}
	
	defineExpose({resetCamera,toggleFullScreen});
</script>


<style lang="less" scoped>
	.containers-media-model{
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		
		background: radial-gradient(center, circle cover, rgb(255, 255, 255) 0%, rgb(224, 227, 234) 100%);
		background: -o-radial-gradient(center, circle cover, rgb(255, 255, 255) 0%, rgb(224, 227, 234) 100%);
		background: -ms-radial-gradient(center, circle cover, rgb(255, 255, 255) 0%, rgb(224, 227, 234) 100%);
		background: -moz-radial-gradient(center, circle cover, rgb(255, 255, 255) 0%, rgb(224, 227, 234) 100%);
		background: -webkit-radial-gradient(center, circle cover, rgb(255, 255, 255) 0%, rgb(224, 227, 234) 100%);
		.fbx{
			width: inherit;
			height: inherit;
		}
	}
	.noBackground{
		background: none;
	}
</style>