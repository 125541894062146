import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {
	requestHttp
} from '@/request/index';
import {
	createComponents
} from '@/utils/components';
import {
	getTime
} from '@/utils/index';
import '@/utils/rem.js';
const app = createApp(App);
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
// import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn';
// import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
	Hljs: hljs,
});

VMdPreview.use(createLineNumbertPlugin());
// VMdPreview.use(createMermaidPlugin());
VMdPreview.use(createTodoListPlugin());

app.use(VMdPreview).use(store);
app.config.globalProperties.$request = requestHttp; //全局挂载
app.config.globalProperties.$getTime = getTime; //全局挂载
//请求网站公共信息
getweb();
async function getweb (){
	await requestHttp({urlName: 'index_index'}).then(res=>{
		app.config.globalProperties.$site=res.data.site;
	}).finally(()=>{
		app.use(router).mount('#app');
		createComponents(app)
	})
}

