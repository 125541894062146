<template>
	<div class="containers-detail-tag"><slot></slot></div>
</template>

<script>
	export default {
		name: 'detail-tag'
	}
</script>

<style lang="less" scoped>
@import url("../../index.less");
.containers-detail-tag{
	height: 1.875rem;
	background: rgba(61,213,152,0.14);
	border-radius: 0.5rem;
	border: 1px solid @primary-color;
	text-align: center;
	line-height:1.875rem;
	font-size: 1rem;
	font-family: @font-family;
	font-weight: 400;
	color: @primary-color;
	margin-bottom: 0.875rem;
	white-space:nowrap;
	padding:0px 0.3125rem;
	box-sizing: border-box;
}
</style>