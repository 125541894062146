<template>
	<div class="containers">
		<ty-nav :fade="false" :is-my="true"></ty-nav>
		<div class="list-body">
			<div class="list-tools">
				<my-tabs :options="['我的收藏','已购模型','我的订单']" v-model="tabIndex"></my-tabs>
			</div>
			<template v-if="tabIndex==0">
				<div class="list-model">
					<omit-model v-for="item in collectArray" class="omit"
					 :free="item.free_switch"
					:mediaType="item.images_videos.length>0?'video':'img'"
					:isCollect="false"
					:node="item"
					:key="item.id"
					></omit-model>
				</div>
			</template>
			<template v-if="tabIndex==1">
				<div class="list-model">
					<omit-model v-for="item in buyArray" class="omit"
					:free="item.free_switch"
					:mediaType="item.moxing.images_videos.length>0?'video':'img'"
					:isCollect="false"
					:node="item.moxing"
					v-show="item.status==1"
					:key="item.id"
					></omit-model>
				</div>
			</template>
			<div class="table-model" v-show="tabIndex==2">
				<table>
					<thead>
						<tr>
							<th>模型名称</th>
							<th>订单号</th>
							<th>订单金额</th>
							<th>下单时间</th>
							<th>订单状态</th>
							<th>操作</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in buyArray" :key="item.id">
							<td>{{item.moxing.name}}</td>
							<td>{{item.order_no}}</td>
							<td>¥{{item.amount}}</td>
							<td>{{formatTime(item.createtime)}}</td>
							<td>
								<template v-if="item.status==0">等待支付</template>
								<template v-if="item.status==-1">取消支付</template>
								<template v-if="item.status==-2">订单超时</template>
								<template v-if="item.status==1">完成支付</template>
							</td>
							<td class="update">
								<template v-if="item.status==0">
									<button class="link-button" @click="tapPay(item.pay_link)">去支付</button><button class="link-button" @click="cancelOrder(item.id)">取消订单</button>
								</template>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<confirm ref="waitPay">您已下单购买此模型，存在待支付订单</confirm>
	</div>
</template>
<script setup>
import {
		onMounted,
		ref,
		watch,
		getCurrentInstance
	} from 'vue';
	const tabIndex = ref(0);
	const collectArray=ref([]);//收藏列表
	const buyArray=ref([]);//已购买的列表
	const waitPay =ref();//等待支付
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	const _this = getCurrentInstance().proxy;
	// 获取当前路由
	const route = useRoute();
	const router = useRouter();
	
	/**
	  * 点击购买
	  */
	const tapPay = (pay_link) =>{
		window.location.href = pay_link;
	};
	
	/**
	 * 取消订单
	 */
	const cancelOrder = (parm)=>{
		_this.$request({
			urlName:'index_cancelOrder',
			data:{
				id:parm
			}
		}).then(res=>{
			find_Buy();
		})
	}
	/**
	 * 查询收藏记录
	 */
	const find_Collect=()=>{
		_this.$request({
			urlName:'account_getFavorites',
		}).then(res=>{
			if (res.code === 1) {
				collectArray.value= res.data;
			}
		})
	};
	/**
	 * 格式时间
	 */
	const formatTime=(data)=>{
		return _this.$getTime(data*1000,'yyyy/MM/dd HH:mm:ss');
	}
	/**
	 * 查询已购买的列表
	 */
	const find_Buy=()=>{
		_this.$request({
			urlName:'account_getOrders',
		}).then(res=>{
			if (res.code === 1) {
				buyArray.value= res.data;
			}
		})
	}

	onMounted(() => {
		tabIndex.value = route.query.index;
		if (route.path === '/my') {
			if (!localStorage.getItem('ba-user-token')) {
				router.replace({ path: '/home' });
			}
		}
		if(tabIndex.value==0){
			find_Collect();
		}else{
			find_Buy();
		}
	})
	watch(
	  () => router.currentRoute.value,
	  (newValue) => {
	    tabIndex.value = newValue.query.index;
	  },
	  { immediate: true ,deep:true}
	);
	
	watch(
	  () => tabIndex.value,
	  (newvalue, oldvalue) => {
	    if(tabIndex.value==0){
	    	find_Collect();
	    }else{
			find_Buy();
		}
	  },{
	    deep: true
	  }
	);

</script>
<style lang="less" scoped>
	@import url("../index.less");

	.containers {
		.list-tools {
			margin-top: 7.625rem;
			padding: 0px 6.25rem;
			box-sizing: border-box;
		}

		.list-body {
			box-sizing: border-box;
			min-height: 100vh;
			padding-bottom: 11.375rem;

			.list-model {
				margin-top: 2.25rem;
				display: grid;
				padding: 0px 6.25rem;
				box-sizing: border-box;
				grid-template-columns: repeat(auto-fill, 367px);
				justify-content: space-between;

				.omit {
					margin-bottom: 5.375rem;
				}
			}
		}

		.table-model {
			padding: 0px 6.125rem;
			box-sizing: border-box;

			table {
				width: 100%;
				margin-top: 3.75rem;
				border-spacing: 0px;
				overflow: hidden;
				border-radius: 1.5rem 1.5rem 0px 0px;
				border-collapse: collapse;

				thead {
					height: 6.25rem;
					background: #F5F5F5;
					border-radius: 1.5rem 1.5rem 0px 0px;

					th {
						text-align: left;
						font-size: 1.5rem;
						font-family: @font-family;
						font-weight: 500;
						color: #333333;
						padding-left: 2.75rem;
						box-sizing: border-box;
					}
				}

				tbody {
					tr {
						transition: all @animation-time;
						height: 6.25rem;
						border-left: 1px solid #F0F0F0;
						border-right: 1px solid #F0F0F0;
						border-bottom: 2px solid #F0F0F0;

						td {
							padding-left: 2.75rem;
							box-sizing: border-box;
							font-size: 1.25rem;
							font-family: @font-family;
							font-weight: 400;
							color: #666666;

							.link-button {
								border: none;
								background-color: rgba(0, 0, 0, 0);
								color: inherit;
								padding: 0px 0.25rem;
								box-sizing: border-box;
								font-size: 1.25rem;
								cursor: pointer;

							}

							.link-button:nth-child(2) {
								border-left: 1.5px solid @primary-color;

								box-sizing: border-box;
							}
						}

						.update {
							color: @primary-color;
						}
					}

					tr:hover {
						background-color: #F0F0F0;
					}
				}
			}
		}

	}
</style>
