import axios from 'axios'

/* eslint-disable */
// const router = routerIndex
// create an axios instance
// baseURL: process.env.NODE_ENV === 'production'?'https://3d.taiyi.cn/':'/',
const service = axios.create({
	baseURL:'/',
	timeout: 30000 // request timeout
})
// request interceptor
service.interceptors.request.use(
	config => {
		config.headers["Client-Env"] = "plat:h5"; // 用于心跳接口
		config.headers['server'] = true;
		config.headers['Cache-Control'] = 'no-cache';
		config.headers['token'] = localStorage.getItem('token'); // 用于心跳接口
		config.headers['ba-user-token'] = localStorage.getItem('ba-user-token')
		if(config.method.toLocaleLowerCase()=="post"){
			config.headers['Content-Type'] = 'multipart/form-data'
		}
		return config
	},
	error => {
		// do something with request error
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */
	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		const res = response.data
		// if the custom code is not 20000, it is judged as an error.
		if (res.code !== 1) {
			// return Promise.reject(new Error(res.msg || 'Error'))
			console.log('请求错误');
			return res;
		} else {
			return res
		}
	},
	error => {
		return Promise.reject(error)
	}
)

export default service
